import { Dropdown, IconButton } from '@teo/components';
import {
  Information,
  Pencil,
  PlusFilled,
  Document,
  Chapter,
  Library,
  Play,
  Image,
  ImageFilled,
} from '@teo/components/icons';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { LessonTreeEdit } from '@/components/LessonTree/LessonTreeEdit';
import { LibraryModal } from '../LibraryModal/LibraryModal';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { axios, publicAxios } from '@/axios';
import { config } from '@/config';
import { uploadLesson, uploadImage } from '@/query/documents';
import { newUid, uploadFile, walkObject, getLocalizedText } from '@/util';
import { uploadVideo } from '@/query/vimeo';
import Hint from '../Hints/Hints';
import { XMLParser, XMLBuilder, XMLValidator } from 'fast-xml-parser';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import i18n from 'i18next';

export const SidebarXApi = ({
  coursecontentId,
  publicationPath,
  publicationType,
  activeNode,
  setActiveNode,
  isDesctop,
}: any) => {
  const { t } = useTranslation();
  const [lessons, setLessons] = useState<any[]>([]);
  const { hints } = useContext(HintsContext);
  const { newPage } = useContext(PageUrlContext);
  const [isAddButtonBottom, setIsAddButtonBottom] = useState(false);
  const { setShowNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (coursecontentId) {
      publicAxios
        .get(
          `${config.s3root}xapi/${coursecontentId}/${publicationPath}/cmi5.xml`
        )
        .then((result) => {
          const parser = new XMLParser({
            ignoreAttributes: false,
            isArray: (name: string) => ['au'].includes(name), // Treat 'au' as an array
          });

          const json = parser.parse(result.data);

          let lessons: any[] = [];
          walkObject(json, (el: any, key: any) => {
            if (key === 'au') {
              lessons.push(...el);
            }
          });
          lessons = lessons.map((au: any) => ({
            id: au['@_id'],
            title: au.title.langstring,
            description: au.description?.langstring || '',
            url: au.url,
            moveOn: au['@_moveOn'],
          }));

          setLessons(lessons);
        })
        .catch((err) => console.error('Failed to load cmi5 manifest:', err));
    }
  }, [coursecontentId]);

  const renderItem = (items: any[], indent = 0) => {
    return (
      <>
        {items.map((item: any, index: number) => (
          <li
            key={item['@_identifier'] || `fallback-key-${index}`}
            style={{ marginLeft: `${indent * 24}px` }}
          >
            <div
              className={clsx(
                'relative my-1 flex cursor-pointer flex-row items-center justify-between rounded-md pl-2 pr-1', // Added justify-between
                { 'bg-[#f8f8f9]': activeNode?.id === item.id }
              )}
              onClick={() => setActiveNode(item)}
            >
              <div className="flex items-center">
                {' '}
                {/* Left side with Document and text */}
                <Document className="m-2 w-6 min-w-6" />
                <p className="w-full max-w-[180px] flex-1 text-sm font-normal text-[#231f20]">
                  {getLocalizedText(item.title, i18n.language)}
                </p>
              </div>
              {activeNode?.id === item.id && (
                <Pencil className="mx-2 w-5 min-w-5 text-[#fdbe06]" />
              )}
            </div>
          </li>
        ))}
      </>
    );
  };

  return (
    <>
      <div
        onClick={(ev) => {
          setActiveNode(null);
        }}
        className={clsx(
          ` relative flex cursor-pointer items-center justify-between rounded-md`,
          !activeNode && 'bg-[#f8f8f9]',
          hints?.id == 'hint_39' &&
            'active-hint-create-page active-hint-parent active-hint-main-menu'
        )}
      >
        <span className="flex items-center pl-2">
          <Hint
            key={`hint_39_${newPage}`}
            id={'hint_39'}
            leftDots={'left-0 top-2'}
            title={t('hint.title_39')}
            order={39}
            style={{
              left: 'calc(100% + 20px)',
              top: '0px',
            }}
          />
          <Information className="m-2 w-6 min-w-6" />
          <p className="text-sm font-normal text-[#231f20]">
            {t(`courseTypes.${publicationType}`)}
          </p>
        </span>
        {!activeNode && <Pencil className="mx-2 w-5 min-w-5 text-[#fdbe06]" />}
      </div>
      <ul className="flex flex-col">{renderItem(lessons, 0)}</ul>
    </>
  );
};
