import React, { useState, useEffect, createRef, useContext } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { config } from '@/config';

export const XApiLessonContent = ({
  node,
  iFrameRef = createRef<any>(),
  coursecontentId,
  courseId,
  publicationPath,
  onExit = () => {},
  autoInit = false,
  itemId,
  width = 'calc(100vw - 380px)',
  hideSidebar,
  notHidden,
}: any) => {
  const { auth } = useContext(AuthContext);
  const authUser: any = auth?.user;
  const [src, setSrc] = useState<string | undefined>(undefined);

  let activityId: any;
  if (courseId) {
    activityId = `course_${courseId}`;
  } else {
    activityId = `preview_${coursecontentId}`;
  }

  useEffect(() => {
    const handleMessage = (event: any) => {
      try {
        const data = JSON.parse(event.data);
        if (data && data.type === 'exit') {
          onExit();
        }
      } catch (e) {
        //ignore
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []); // Empty dependency array, runs only on mount and unmount

  useEffect(() => {
    if (node?.url && authUser?.id) {
      const actor = JSON.stringify({
        objectType: 'Agent',
        mbox: authUser.email ? `mailto:${authUser.email}` : undefined,
        name: `${authUser.firstName || ''} ${authUser.lastName || ''}`.trim(),
        account: {
          homePage: window.location.hostname,
          name: `${authUser.id}`,
        },
      });
      const launchParams = new URLSearchParams({
        fetch: `${config.backend}/xapi/fetch?jwt=${auth?.token}`,
        actor,
        activityId: activityId,
        endpoint: `${config.backend}/xapi/endpoint`,
        registration: auth?.token as string,
        returnURL: `${config.backend}/xapi/exit`,
      }).toString();

      let seperator;
      if (node.url.includes('?')) {
        seperator = '&';
      } else {
        seperator = '?';
      }

      let basePath;
      if (node.url.startsWith('http')) {
        basePath = node.url;
      } else {
        basePath = `${config.s3root}xapi/${coursecontentId}/${publicationPath}/'`;
      }

      setSrc(`${basePath}${seperator}${launchParams}`);
    }
  }, [node, coursecontentId, publicationPath, authUser]);

  return (
    <iframe
      ref={iFrameRef}
      className={notHidden ? '' : '!hidden'}
      style={{
        width: width,
        minWidth: width,
        height: 'calc(100vh - 150px)',
        margin: '0',
        paddingBottom: '50px',
        border: '0',
        padding: '0',
      }}
      src={src}
    ></iframe>
  );
};

export default XApiLessonContent;
