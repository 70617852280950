import { useState, useContext, useEffect, useRef, createRef } from 'react';
import { useGet } from '@/query/fetchHooks';
import { useParams, useNavigate } from 'react-router-dom';
import { NavContext } from '@/components/NavContext';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
import ScormLessonHeader from './ScormLessonHeader';
import ScormLessonContent from './ScormLessonContent';
import { useMediaQuery } from 'react-responsive';
import { ScormService } from './ScormService';
import {
  mongoEncodeKey,
  newUid,
  useStateAndRef,
  nthIndexOf,
  nthLastIndexOf,
} from '@/util';
import { config } from '@/config';
import { axios } from '@/axios';
import { SidebarScorm } from '@/components/LessonTree/SidebarScorm';
import { SidebarScormStudent } from '@/components/LessonTree/SidebarScormStudent';
import { useTranslation } from 'react-i18next';
import { Sidebar } from '@teo/components/icons';
import { IconButton } from '@teo/components';

const iFrameRef = createRef<any>();

const ScormLessonPage = ({ courseInfo }: any) => {
  const { setNavDisabled } = useContext(NavContext);
  const [activeNode, realSetActiveNode, activeNodeRef] = useStateAndRef(null);
  const [hideSidebar, setHideSidebar] = useState<boolean>(false);
  const [numLesAs, setNumLesAs] = useState<any>(1);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isDesctop = useMediaQuery({
    query: '(max-width: 1023px)',
  });

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  });

  const [navigateTo, setNavigateTo] = useState<null | string>(null);

  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  }, [navigateTo]);

  let lastWidgetId = 0;
  const scormWidgets: any[] = [];

  const hideScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const showScroll = () => {
    document.body.style.overflow = 'auto';
  };

  const parseInteraction = (path: string, data: any) => {
    path = path.split('.').slice(3).join('.');
    switch (path) {
      case 'id':
        lastWidgetId = mongoEncodeKey(data[0]);
        scormWidgets[lastWidgetId] = { id: lastWidgetId };
        break;
      case 'type':
        scormWidgets[lastWidgetId].type = data[0];
        break;
      case 'learner_response':
        scormWidgets[lastWidgetId].learner_response = data[0];
        break;
      case 'result':
        scormWidgets[lastWidgetId].result = data[0];
        break;
      case 'correct_responses.0.pattern':
        scormWidgets[lastWidgetId].correct_responses = data[0];
        break;
      case 'description':
        scormWidgets[lastWidgetId].description = data[0];
        break;
      case 'weighting':
        scormWidgets[lastWidgetId].weighting = parseFloat(data[0]);
        break;
      case 'latency':
        scormWidgets[lastWidgetId].latency = data[0];
        break;
      case 'timestamp':
        scormWidgets[lastWidgetId].timestamp = data[0];
        break;
      default:
        console.error("I can't deal with", path, data);
    }
  };

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const { courseId, organisationId } = useParams();
  let { studentId } = useParams();

  let itSMe = false;
  if (!studentId) {
    itSMe = true;
    studentId = authUser?.id as unknown as string;
  }
  const isTeacher = !itSMe;
  const [student, setstudent, studentRef] = useStateAndRef(null);
  const [course, setCourse] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [initialised, setInitialised] = useState<boolean>(false);

  const basePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 2)
  );
  let typePath = course?.courseType + 's';
  if (typePath === 'leervacatures') typePath = 'vacancies';
  const returnPath = `${basePath}/${typePath}/${course?.id}`;

  const isPreview =
    window.location.pathname.includes('/preview/') || !studentId;

  const { refetch: refetchStudentInfo } = useGet(
    `/student/${courseId}/${studentId}`,
    {
      enabled: !!studentId,
      onSuccess: (result: any) => {
        setstudent(result?.data || {});
      },
    }
  );

  useGet(`/coursedetail/${courseId}`, {
    onSuccess: (result: any) => {
      setCourse(result?.data || {});
    },
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const iSsmallLaptop = useMediaQuery({
    query: '(max-width: 1199px)',
  });

  useEffect(() => {
    if (isMobile) {
      setHideSidebar(true);
    } else {
      setHideSidebar(false);
    }
  }, [isMobile]);

  const subscriptions: any[] = [];
  let loaded = false;

  const updateFollow = async (data: any) => {
    setstudent((student: any) => Object.assign({}, student, data));
    Object.assign(studentRef.current, data);
    await axios.put(
      `${config.backend}/follow/${student.courseId}/${student.userId}`,
      data
    );
  };

  useEffect(() => {
    if (!course || !student || !iFrameRef.current || !activeNode || initialised)
      return;
    setInitialised(true);
  }, [course, student, iFrameRef.current, activeNode]);

  useEffect(() => {
    if (!initialised) return;
    hideScroll();
    subscriptions.push(
      ScormService.getInstance().Ready.subscribe(async (temp: any) => {
        const win = iFrameRef.current.contentWindow;
        win.postMessage({ type: 'data', data: scormData }, '*');
      })
    );
    subscriptions.push(
      ScormService.getInstance().Commit.subscribe(async () => {})
    );
    subscriptions.push(
      ScormService.getInstance().Terminate.subscribe(async () => {
        for (const subscription of subscriptions) {
          subscription.unsubscribe();
        }
        setNavigateTo(returnPath);
        return;
      })
    );
    subscriptions.push(
      ScormService.getInstance().SetValue.subscribe(async (temp: any) => {
        const student = studentRef.current;
        if (student && !student.startDate) {
          updateFollow({
            startDate: new Date(),
          });
        }
        if (!loaded) loaded = true;
        const [path, ...data] = temp;
        if (path.startsWith('cmi.interactions.')) {
          parseInteraction(path, data);
        } else if (path === 'cmi.location') {
          if (student) {
            updateFollow({
              location: data[0],
            });
          }
        } else if (path === 'cmi.success_status') {
          //“passed”, “failed”, “unknown”,
          //not implemented
          if (data[0] && data[0] === 'passed') {
            if (!student.score) {
              updateFollow({
                score: 1 * student.maxScore || 1,
                maxScore: student.maxScore || 1,
              });
            }
          } else if (data[0] && data[0] === 'failed') {
            if (!student.score) {
              updateFollow({
                score: 0 * student.maxScore || 1,
                maxScore: student.maxScore || 1,
              });
            }
          }
        } else if (path === 'cmi.suspend_data') {
          updateFollow({
            suspend_data: data[0],
          });
        } else if (path === 'cmi.completion_status') {
          //“completed”, “incomplete”, “not attempted”, “unknown”
          if (data[0] && data[0] === 'completed') {
            setSubmitted(true);
            const progressLessonsTotal = numLesAs || 1;
            const progressLessonsCompleted = numLesAs || 1;
            updateFollow({
              progressLessonsCompleted,
              progressLessonsTotal,
              progress: progressLessonsCompleted / progressLessonsTotal,
            });
          }
        } else if (path === 'cmi.score.raw') {
          //float
          if (student) {
            updateFollow({
              score: parseFloat(data[0]),
            });
          }
        } else if (path === 'cmi.score.scaled') {
          //float
          if (student) {
            //this should put the correct scores regardless in which order max_score,
            if (student.score) {
              updateFollow({
                maxScore:
                  parseFloat(student.score) / (parseFloat(data[0]) || 1),
              });
            } else if (student.maxScore) {
              updateFollow({
                score: parseFloat(data[0]) * parseFloat(student.maxScore),
              });
            } else {
              //score on 20
              updateFollow({
                score: parseFloat(data[0]) * 20,
                maxScore: 20,
              });
            }
          }
        } else if (path === 'cmi.score.max') {
          //float
          if (student) {
            updateFollow({
              maxScore: parseFloat(data[0]),
            });
          }
        } else if (path === 'cmi.exit') {
          /*
          for (const subscription of subscriptions) {
            subscription.unsubscribe();
          }
          const basePath = window.location.pathname.slice(
            0,
            nthLastIndexOf(window.location.pathname, '/', 2)
          );
          return;
          setNavigateTo(`${basePath}/courses/${student.courseId}`);
          */
          //} else if (path === 'cmi.score.min') {
          //not implemented
        } else if (path === 'cmi.core.lesson_status') {
          setSubmitted(true);
          const progressLessonsTotal = numLesAs || 1;
          const progressLessonsCompleted = Math.max(
            (student.progressLessonsCompleted || 0) + 1,
            progressLessonsTotal
          );

          updateFollow({
            progressLessonsCompleted,
            progressLessonsTotal,
            progress: progressLessonsCompleted / progressLessonsTotal,
          });

          if (data[0] && data[0] === 'passed') {
            if (!student.score) {
              updateFollow({
                score: 1 * student.maxScore || 1,
                maxScore: student.maxScore || 1,
              });
            }
          } else if (data[0] && data[0] === 'failed') {
            if (!student.score) {
              updateFollow({
                score: 0 * student.maxScore || 1,
                maxScore: student.maxScore || 1,
              });
            }
          }
        } else {
          console.error("I can't deal with", temp);
        }
      })
    );

    //this part prepares the data for GetValue calls. It then sends that data to the iframe, which will cache it
    //and be able to repond immediately to queries

    const scormData: any = {};

    //cmi.success_status: //“passed”, “failed”, “unknown”,
    try {
      const score = student.score / student.maxScore;
      if (student.status !== 'done') {
        scormData['cmi.success_status'] = 'unknown';
      } else if (score >= course.minScore) {
        scormData['cmi.success_status'] = 'passed';
      } else {
        scormData['cmi.success_status'] = 'failed';
      }
    } catch (e) {
      scormData['cmi.success_status'] = 'unknown';
    }
    //cmi.completion_status: “completed”, “incomplete”, “not attempted”, “unknown
    try {
      if (student.status === 'done') {
        scormData['cmi.completion_status'] = 'completed';
      } else {
        if (student.progress === 0) {
          scormData['cmi.completion_status'] = 'not attempted';
        } else {
          scormData['cmi.completion_status'] = 'incomplete';
        }
      }
    } catch (e) {
      scormData['cmi.completion_status'] = 'unknown';
    }
    //cmi.mode (“browse”, “normal”, “review”, RO)
    try {
      if (isPreview) {
        scormData['cmi.mode'] = 'browse';
      } else if (student.status === 'done') {
        scormData['cmi.mode'] = 'review';
      } else {
        scormData['cmi.mode'] = 'normal';
      }
    } catch (e) {
      scormData['cmi.mode'] = 'normal';
    }

    if (student && student.location) {
      scormData['cmi.location'] = student.location;
    }

    if (student && student.suspend_data) {
      scormData['cmi.suspend_data'] = student.suspend_data;
    }

    return () => {
      //destructor of sorts
      showScroll();
      for (const subscription of subscriptions) {
        subscription.unsubscribe();
      }
    };
  }, [initialised]);

  if (!course) return null;

  return (
    <div>
      <ScormLessonHeader
        returnPath={returnPath}
        student={student}
        isPreview={isPreview}
        isMobile={isMobile}
        iSsmallLaptop={iSsmallLaptop}
        courseId={courseId}
        hideSidebar={hideSidebar}
        setHideSidebar={setHideSidebar}
      />
      <div className="flex">
        {!hideSidebar && (
          <div className="left-0 flex w-full flex-col border-r border-solid border-grey-02 p-5 px-2 duration-500 ease-out md:!max-w-[380px]">
            <div className="p-5 md:sticky md:top-[80px] md:max-h-[calc(100vh_-_80px)] md:w-[380px] md:overflow-y-auto">
              <p className="mt-2 mb-4 pl-2 text-base font-medium">Content</p>
              <SidebarScormStudent
                key={course?.id + '_' + course?.publicationPath}
                coursecontentId={course.masterId}
                publicationType={course.publicationType}
                publicationPath={course.publicationPath}
                activeNode={activeNode}
                autoInit={true}
                setNumLesAs={setNumLesAs}
                setActiveNode={realSetActiveNode}
                isDesctop={isDesctop}
              />
            </div>
          </div>
        )}
        <div>
          {/*course && <>{`${config.s3root}scorm/landing.html?id=${course.masterId || course.coursecontentd}`}</>*/}
          {course && activeNode && (
            <ScormLessonContent
              notHidden={!isMobile || hideSidebar}
              iFrameRef={iFrameRef}
              coursecontentId={course.masterId}
              publicationPath={course.publicationPath}
              autoInit={true}
              node={activeNode}
              itemId={activeNode['@_identifier']}
              width={hideSidebar ? '100vw' : 'calc(100vw - 380px)'}
            />
          )}
        </div>
      </div>
      <div data-testid="hide-sidebar" className="hidden md:block">
        <IconButton
          className="fixed left-2 bottom-[90px] z-[4] bg-white hover:bg-[#f4f4f4]"
          Icon={Sidebar}
          variant="outline"
          onClick={() => setHideSidebar(!hideSidebar)}
        />
      </div>
    </div>
  );
};

export default ScormLessonPage;
