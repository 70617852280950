import { lazy } from 'react';
import { createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import Auth from './pages/Auth';
import AuthForgotPassword from './pages/Auth/ForgotPassword';
import AuthResetPasswordSuccess from './pages/Auth/AuthResetPasswordSuccess';
import SetNewPassword from './pages/Auth/SetNewPassword';
import AuthLogin from './pages/Auth/Login';
import AuthRegister from './pages/Auth/Register';
import RegisterSucessPage from './pages/Auth/RegisterSuccess';
import Create from './pages/Protected/Create';
import CreateAssessments from './pages/Protected/Create/Assessments';
import CreateCourses from './pages/Protected/Create/Courses';
import CreateGrowthTrajectories from './pages/Protected/Create/GrowthTrajectories';
import CreateIndex from './pages/Protected/Create/Index';
import LibraryCreate from './pages/Protected/Create/Library/Index';
import LibraryDetail from './pages/Protected/Create/Library/LibraryDetail';
import LibraryDetailLesson from './pages/Protected/Create/Library/LibraryDetailLesson';
import CreateVacancies from './pages/Protected/Create/Vacancies';
import CreateEditCourse from './pages/Protected/Create/EditCourse/EditCourse';
import CreateViewCourse from './pages/Protected/Create/Preview/PreviewCoures';
import LessonViewPreview from './pages/Protected/Create/Preview/PreviewLesson';
import PreviewAsset from './pages/Protected/Create/Preview/PreviewAsset';

import FollowUp from './pages/Protected/FollowUp';
import PublicationCourses from './pages/Protected/FollowUp/PublicationCourses';
import FollowUpAddUsersToCourse from './pages/Protected/FollowUp/AddUsersToCourse/AddUsersToCourse';
import FollowUpCourseSettings from './pages/Protected/FollowUp/AddUsersToCourse/CourseSettings';
import FollowUpCourseDetails from './pages/Protected/FollowUp/CourseDetails';
import FollowUpTeamCourseDetails from './pages/Protected/FollowUp/TeamCourseDetails';
import FollowUpGrowthTrajectories from './pages/Protected/FollowUp/GrowthTrajectories';
import FollowUpIndex from './pages/Protected/FollowUp/FollowUpOverviewPage/Index';
import FollowUpUsers from './pages/Protected/FollowUp/Users';
import FollowUpUserDetails from './pages/Protected/FollowUp/UserDetails';
import FollowUpTeamDetails from './pages/Protected/FollowUp/TeamDetails';
import Home from './pages/Protected/HomePage';
import Learn from './pages/Protected/Learn';
import LearnAssessments from './pages/Protected/Learn/Assessments';
import LearnCourses from './pages/Protected/Learn/Courses';
import LearnCourseDetails from './pages/Protected/Learn/Courses/CourseDetail';
import LearnLesson from './pages/Protected/Learn/Courses/Lessons/LessonPage';
import ScormLessonPage from './pages/Protected/Learn/Courses/ScormCourse/ScormLessonPage';
import FirstLesson from './pages/Protected/Learn/Courses/Lessons/FirstLesson';
import Xapi from './pages/Protected/Learn/Courses/Lessons/Xapi';
import Scorm from './pages/Protected/Learn/Courses/Lessons/Scorm';
import ExtraInfoLesson from './pages/Protected/Learn/Courses/Lessons/PreviewExtraInfo';
import LearnGrowthTrajectories from './pages/Protected/Learn/GrowthTrajectories';
import LearnIndex from './pages/Protected/Learn/LearnPage/Index';
import LearnVacancies from './pages/Protected/Learn/Vacancies';
import LearnInstructions from './pages/Protected/Learn/Instructions';
import LearnOnboarding from './pages/Protected/Onboarding/Index';
import ResultPage from './pages/Protected/FollowUp/ResultPage';
import Manage from './pages/Protected/Manage';
import ManageCategories from './pages/Protected/Manage/Categories/Categories';
import ManageCertificates from './pages/Protected/Manage/Certificates/Certificates';
import ManageCompetencies from './pages/Protected/Manage/Competencies/Competencies';
import ManageTags from './pages/Protected/Manage/Tags/Tags';
import ManageTeams from './pages/Protected/Manage/TeamsManage';
//import ManageUsers from './pages/Protected/Manage/UsersManage';
import ManageUsersTeams from './pages/Protected/Manage/Users/Users';
import ManageUserDetails from './pages/Protected/Manage/Users/UserDetail/UserDetails';
import ManagePageAddUsersToOrganization from './pages/Protected/Manage/AddUsersToOrganizationManagePage/AddUsersToOrganizationManagePage';
import ManagePageAddCompetencyProfile from './pages/Protected/Manage/AddCompetencyProfilePage/AddCompetencyProfilePage';
import ManagePageEditCompetencyProfile from './pages/Protected/Manage/EditCompetencyProfilePage/EditCompetencyProfilePage';
import ManageTeamsDetails from './pages/Protected/Manage/TeamsDetails/ManageTeamsDetails';
import ManageAddUsersToTeam from './pages/Protected/Manage/AddUsersToTeamManagePage/AddUsersToTeamManagePage';
import Members from './pages/Protected/Members';
import NotFound from './pages/NotFound';
import Organisation from './pages/Protected/Organisation';
import CreateOrganisation from './pages/Protected/Organisation/CreateOrganisation/CreateOrganisation';
import OrganisationApi from './pages/Protected/Organisation/Api';
import OrganisationBrandingAndStyling from './pages/Protected/Organisation/BrandingAndStyling';
import OrganisationCommunication from './pages/Protected/Organisation/Communication';
import OrganisationDetails from './pages/Protected/Organisation/OrganisationDetails/Index';
import OrganisationLicensingAndBilling from './pages/Protected/Organisation/LicensingAndBilling/Index';
import OrganisationSupportCredits from './pages/Protected/Organisation/SupportCredits';
import Profile from './pages/Protected/Profile';
import ProfileDetails from './pages/Protected/Profile/Details';
import ProfileFollowUp from './pages/Protected/Profile/FollowUp/ProfileFollowUp';
import EmailsAndNotifications from './pages/Protected/Profile/EmailsAndNotifications';
import ProfileEmailAndPassword from './pages/Protected/Profile/EmailAndPassword';
import ProfileAccount from './pages/Protected/Profile/Account';
import Protected from './pages/Protected';
import Footer from './components/Footer/Footer';
import TermsAndConditions from './components/Footer/TermsAndConditions';
import Privacy from './components/Footer/Privacy';
import Root from './pages/Root';
import CreateAssessmentsPage from './pages/Protected/Create/Assessments';
import NoAccessPage from '@/pages/NoAccessPage';
import ReportPage from './pages/Protected/Organisation/ReportPage/Report';
import Four04 from '@/pages/Four04';
import FaqsOverview from '@/pages/Unprotected/Faq/FaqsOverview';
import Help from './pages/Unprotected/Help';
import Tutorial from './pages/Unprotected/Tutorial/Tutorial';
import Tips from './pages/Unprotected/Tips';
import Contact from './pages/Unprotected/Contact';
import HelpIndex from '@/pages/Unprotected/Overview/Index';
import Notifications from '@/pages/Unprotected/Notifications';
import UpdateEdit from '@/pages/Unprotected/Overview/UpdateEdit';
import UpdateOverview from '@/pages/Unprotected/Overview/UpdateOverview';
import FaqsDetailsEdit from '@/pages/Unprotected/Faq/FaqsDetailsEdit';
import PdfIndex from '@/pages/Protected/PdfIndex';
import PdfTitle from '@/pages/Protected/PdfTitle';
import CookiePolicy from './components/Footer/Cookies';
import XApiLessonPage from './pages/Protected/Learn/Courses/XApiCourse/XApiLessonPage';

//unlazy:
//lazy\(\n?\s*?\(\)\s*?=>\s*?\n?\s*import\(\n?\s*(.*?)\n?\s*\).*?\n?\s*\)
//(await import ($1)).default

const NavigateRootWithLanguage = () => {
  const { i18n } = useTranslation();
  return <Navigate to={`/${i18n.language}`} replace />;
};

const t = i18n?.t || ((str: string) => str);

const routes = createRoutesFromElements(
  <>
    <Route path="/" element={<NavigateRootWithLanguage />} />

    <Route
      handle={{ name: 'breadcrumbs.HOME' }}
      path=":lang/"
      element={<Root />}
    >
      <Route
        path="learn/:organisationId/courses/:courseId/xapi"
        element={<Xapi />}
      />
      <Route
        path="learn/:organisationId/courses/:courseId/scorm"
        element={<Scorm />}
      />

      <Route
        handle={{ name: 'breadcrumbs.HELP' }}
        path="help"
        element={<Help />}
      >
        <Route index element={<HelpIndex />} />
        <Route
          handle={{ name: 'breadcrumbs.UPDATES' }}
          path="updates/overview"
          element={<UpdateOverview />}
        />
        <Route
          handle={{ name: 'breadcrumbs.EDIT_UPDATE' }}
          path="updates/:updateId"
          element={<UpdateEdit />}
        />

        <Route handle={{ name: 'breadcrumbs.FAQ' }} path="faqs">
          <Route index element={<FaqsOverview />} />
          <Route
            handle={{ name: 'breadcrumbs.TOPIC' }}
            element={<FaqsDetailsEdit />}
            path="new"
          />
          <Route handle={{ name: 'breadcrumbs.TOPIC' }} path=":faqId">
            <Route index element={<FaqsOverview />} />
            <Route path="edit" element={<FaqsDetailsEdit />} />
          </Route>
        </Route>
        <Route
          handle={{ name: 'breadcrumbs.TUTORIAL' }}
          path="tutorial"
          element={<Tutorial />}
        />
        <Route
          handle={{ name: 'breadcrumbs.TIPS' }}
          path="tips"
          element={<Tips />}
        />
        <Route
          handle={{ name: 'breadcrumbs.CONTACT' }}
          path="contact"
          element={<Contact />}
        />
        <Route
          handle={{ name: 'breadcrumbs.NOTIFICATION' }}
          path="notifications"
          element={<Notifications />}
        />
      </Route>

      <Route index element={<Home />} />

      <Route
        path="cursus/:path"
        element={
          <Navigate
            replace
            to={window.location.pathname.replace('/cursus/', '/learn/courses/')}
          />
        }
      />
      <Route
        path="preview/:courseId/lesson/:lessonId?"
        element={<LearnLesson />}
      />
      <Route
        path="previewcourse/:coursecontentId/pdfindex"
        element={<PdfIndex />}
      />
      <Route
        path="previewcourse/:coursecontentId/pdftitle"
        element={<PdfTitle />}
      />

      <Route path="previewAsset/:assetId" element={<PreviewAsset />} />
      <Route path="previewLesson/:lessonId" element={<LessonViewPreview />} />
      <Route
        path="previewLesson/:lessonId/version/:versionId"
        element={<LessonViewPreview />}
      />
      <Route
        handle={{ name: 'breadcrumbs.VIEWCOURSE' }}
        path="previewPublication/:coursecontentId"
        element={<CreateViewCourse />}
      />

      <Route path="members" element={<Members />} />
      <Route path="voorwaarden" element={<TermsAndConditions />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="cookies" element={<CookiePolicy />} />

      <Route
        handle={{ name: 'breadcrumbs.LEARN' }}
        path="learn"
        element={<Learn />}
      ></Route>
      <Route path="dashboard" element={<Navigate to="/learn" replace />} />

      <Route
        handle={{ name: 'breadcrumbs.LEARN' }}
        path="learn/:organisationId?"
        element={<Learn />}
      >
        <Route index element={<LearnIndex />} />

        <Route handle={{ name: 'breadcrumbs.COURSES' }} path="scormCourses">
          <Route path=":courseId">
            <Route index element={<ScormLessonPage />} />
          </Route>
        </Route>

        <Route handle={{ name: 'breadcrumbs.COURSES' }} path="xApiCourses">
          <Route path=":courseId">
            <Route index element={<XApiLessonPage />} />
          </Route>
        </Route>

        <Route handle={{ name: 'breadcrumbs.COURSES' }} path="courses">
          <Route index element={<LearnCourses />} />
          <Route path=":courseId">
            <Route index element={<LearnCourseDetails />} />

            {/* <Route path="onboarding" element={<LearnOnboarding />} /> */}
            {/* <Route path="xapi" element={<Xapi />} /> */}
            {/* <Route path="scorm" element={<Scorm />} /> */}

            <Route path="lesson" element={<FirstLesson />} />
            <Route path="lesson/:lessonId">
              <Route index element={<LearnLesson />} />
              <Route path="student/:studentId" element={<LearnLesson />} />
              <Route path="extraInfo/:extraInfoId">
                <Route index element={<ExtraInfoLesson />} />
              </Route>
            </Route>
            <Route path="onboarding" element={<LearnOnboarding />} />
          </Route>
        </Route>
        <Route
          path="growth-trajectories"
          element={<LearnGrowthTrajectories />}
        />
        <Route path="assessments" handle={{ name: 'breadcrumbs.ASSESSMENTS' }}>
          <Route index element={<LearnAssessments />} />
          <Route path=":courseId">
            <Route index element={<LearnCourseDetails />} />

            {/* <Route path="onboarding" element={<LearnOnboarding />} /> */}
            <Route path="xapi" element={<Xapi />} />
            <Route path="lesson" element={<FirstLesson />} />
            <Route path="lesson/:lessonId">
              <Route index element={<LearnLesson />} />
              <Route path="student/:studentId" element={<LearnLesson />} />
              <Route path="extraInfo/:extraInfoId">
                <Route index element={<ExtraInfoLesson />} />
              </Route>
            </Route>
            <Route path="onboarding" element={<LearnOnboarding />} />
          </Route>
        </Route>
        <Route path="vacancies" handle={{ name: 'breadcrumbs.VACANCIES' }}>
          <Route index element={<LearnVacancies />} />
          <Route path=":courseId">
            <Route index element={<LearnCourseDetails />} />

            {/* <Route path="onboarding" element={<LearnOnboarding />} /> */}
            <Route path="xapi" element={<Xapi />} />
            <Route path="lesson" element={<FirstLesson />} />
            <Route path="lesson/:lessonId">
              <Route index element={<LearnLesson />} />
              <Route path="student/:studentId" element={<LearnLesson />} />
              <Route path="extraInfo/:extraInfoId">
                <Route index element={<ExtraInfoLesson />} />
              </Route>
            </Route>
            <Route path="onboarding" element={<LearnOnboarding />} />
          </Route>
        </Route>
        <Route
          handle={{ name: 'breadcrumbs.INSTRUCTIONS' }}
          path="instructions"
        >
          <Route index element={<LearnInstructions />} />
          <Route path=":courseId">
            <Route index element={<LearnCourseDetails />} />
            <Route path="xapi" element={<Xapi />} />
            <Route path="lesson" element={<FirstLesson />} />
            <Route path="lesson/:lessonId">
              <Route index element={<CreateViewCourse instruction={true} />} />
              <Route path="student/:studentId" element={<LearnLesson />} />
              <Route path="extraInfo/:extraInfoId">
                <Route index element={<ExtraInfoLesson />} />
              </Route>
            </Route>
            <Route path="onboarding" element={<LearnOnboarding />} />
          </Route>
        </Route>
        <Route
          path="resultaat/:courseId/:studentId/:lessonId"
          element={<ResultPage resultPage={true} />}
        />
      </Route>

      <Route
        path="learn/courses/:courseId/onboarding"
        element={<LearnOnboarding />}
      />

      <Route
        handle={{
          name: ({ auth }: any) =>
            `${t('breadcrumbs.CREATE')} ${auth?.organisationName}`,
        }}
        path="create/:organisationId"
        element={<Create />}
      >
        <Route index element={<CreateIndex />} />

        {[
          {
            path: 'courses',
            courseType: 'course',
            title: t('pages.follow_up.courses_title'),
            breadcrumbs: 'breadcrumbs.COURSES',
          },
          {
            path: 'assessments',
            courseType: 'assessment',
            title: t('LearningVacancy'),
            breadcrumbs: 'breadcrumbs.ASSESSMENTS',
          },
          {
            path: 'vacancies',
            courseType: 'leervacature',
            title: t('Assessment'),
            breadcrumbs: 'breadcrumbs.VACANCIES',
          },
          {
            path: 'instructions',
            courseType: 'instruction',
            title: t('breadcrumbs.INSTRUCTIONS'),
            breadcrumbs: 'breadcrumbs.INSTRUCTIONS',
          },
        ].map((data) => (
          <Route
            key={data.path}
            path={data.path}
            handle={{ name: data.breadcrumbs }}
          >
            <Route
              index
              element={<CreateCourses courseType={data.courseType} />}
            />
            <Route
              handle={{ name: 'breadcrumbs.EDITCOURSE' }}
              path=":coursecontentId"
              element={<CreateEditCourse />}
            />
            <Route
              handle={{ name: 'breadcrumbs.VIEWCOURSE' }}
              path=":coursecontentId/preview"
              element={<CreateViewCourse />}
            />
            <Route
              handle={{ name: 'breadcrumbs.VIEWCOURSE' }}
              path=":coursecontentId/previewanswers"
              element={<CreateViewCourse showAnswers={true} />}
            />
            <Route
              handle={{ name: 'breadcrumbs.EDITCOURSE' }}
              path=":coursecontentId/lesson/:lessonId/preview"
              element={<LessonViewPreview />}
            />
          </Route>
        ))}

        <Route handle={{ name: 'breadcrumbs.LIBRARY' }} path="library">
          <Route index element={<LibraryCreate />} />
          <Route
            handle={{ name: 'breadcrumbs.USER_DETAILS' }}
            path="chapter/:chapterId"
            element={<LibraryDetail />}
          />
          <Route
            handle={{ name: 'breadcrumbs.USER_DETAILS' }}
            path="lesson/:lessonId"
          >
            <Route index element={<LibraryDetailLesson />} />
            <Route
              handle={{ name: 'breadcrumbs.EDITCOURSE' }}
              path="preview"
              element={<LessonViewPreview returnPath={-1} />}
            />
          </Route>
        </Route>
      </Route>

      <Route
        handle={{
          name: ({ auth }: any) =>
            `${t('breadcrumbs.FOLLOW-UP')} ${auth?.organisationName}`,
        }}
        path="follow-up/:organisationId?"
        element={<FollowUp />}
      >
        <Route
          path="result/:courseId/:studentId/lesson/:lessonId?"
          element={<LearnLesson result={true} />}
        />

        <Route index element={<FollowUpIndex />} />
        <Route
          handle={{ name: 'breadcrumbs.CREATECOURSE' }}
          path="create"
          element={<FollowUpAddUsersToCourse />}
        />
        <Route
          path="resultaat/:courseId/:studentId/:lessonId"
          element={<ResultPage resultPage={true} isTeacher={true} />}
        />

        <Route
          path="correction/:courseId/:studentId/:lessonId"
          element={<ResultPage coorectionPage={true} isTeacher={true} />}
        />
        <Route handle={{ name: 'breadcrumbs.USERS' }} path="users">
          <Route index element={<FollowUpUsers tab="users" />} />
          <Route
            handle={{ name: 'breadcrumbs.USER_DETAILS' }}
            path=":userId"
            element={<FollowUpUserDetails />}
          />
        </Route>
        <Route handle={{ name: 'breadcrumbs.TEAMS' }} path="teams">
          <Route index element={<FollowUpUsers tab="teams" />} />
          <Route handle={{ name: 'breadcrumbs.TEAM_DETAILS' }} path=":teamId">
            <Route index element={<FollowUpTeamDetails />} />
            <Route
              handle={{ name: 'breadcrumbs.COURSE_DETAILS' }}
              path="courses/:courseId"
              element={<FollowUpTeamCourseDetails />}
            />
          </Route>
        </Route>

        {[
          {
            path: 'courses',
            courseType: 'course',
            title: 'breadcrumbs.COURSES',
            breadcrumbs: 'breadcrumbs.COURSES',
          },
          {
            path: 'assessments',
            courseType: 'assessment',
            title: 'breadcrumbs.ASSESSMENTS',
            breadcrumbs: 'breadcrumbs.ASSESSMENTS',
          },
          {
            path: 'vacancies',
            courseType: 'leervacature',
            title: 'breadcrumbs.VACANCIES',
            breadcrumbs: 'breadcrumbs.VACANCIES',
          },
          {
            path: 'instructions',
            courseType: 'instruction',
            title: 'breadcrumbs.INSTRUCTIONS',
            breadcrumbs: 'breadcrumbs.INSTRUCTIONS',
          },
        ].map((data) => (
          <Route
            handle={{ name: data.breadcrumbs }}
            key={data.path}
            path={data.path}
          >
            <Route
              index
              element={
                <PublicationCourses
                  courseType={data.courseType}
                  title={data.title}
                />
              }
            />

            <Route path="details">
              <Route
                handle={{ name: 'breadcrumbs.COURSE_DETAILS' }}
                path=":courseId"
                element={<FollowUpTeamCourseDetails />}
              />
            </Route>
            <Route
              handle={{ name: 'breadcrumbs.DETAILS' }}
              path=":coursecontentId"
              element={<FollowUpCourseDetails courseType={data.courseType} />}
            />
            <Route
              handle={{ name: 'breadcrumbs.ADD_USERS' }}
              path=":courseId/addUsers"
              element={<FollowUpAddUsersToCourse />}
            />
            <Route
              handle={{ name: 'breadcrumbs.ADD_USERS' }}
              path=":courseId/settings"
              element={<FollowUpCourseSettings />}
            />
            <Route
              handle={{ name: 'breadcrumbs.CREATECOURSE' }}
              path="edit"
              element={<FollowUpAddUsersToCourse />}
            />
          </Route>
        ))}

        {/*
        <Route handle={{ name: 'breadcrumbs.COURSES' }} path={["courses"]}>
          <Route index element={<FollowUpCourses />} />
          <Route
            handle={{ name: 'breadcrumbs.COURSE_DETAILS' }}
            path=":coursecontentId"
            element={<FollowUpCourseDetails />}
          />
          <Route
            handle={{ name: 'breadcrumbs.ADD_USERS' }}
            path=":courseId/addUsers"
            element={<FollowUpAddUsersToCourse />}
          />
          <Route
            handle={{ name: 'breadcrumbs.ADD_USERS' }}
            path=":courseId/settings"
            element={<FollowUpCourseSettings />}
          />
          <Route
            handle={{ name: 'breadcrumbs.CREATECOURSE' }}
            path="edit"
            element={<FollowUpAddUsersToCourse />}
          />
          <Route
            handle={{ name: 'breadcrumbs.COURSE_DETAILS' }}
            path="details/:courseId"
            element={<FollowUpTeamCourseDetails />}
          />
        </Route>
        */}

        {/*
        <Route
          path="growth-trajectories"
          element={<FollowUpGrowthTrajectories />}
          handle={{ name: 'breadcrumbs.GROWTHTRAJECTORIES' }}
        />
        <Route
          path="assessments"
          element={<FollowUpAssessments />}
          handle={{ name: 'breadcrumbs.ASSESSMENTS' }}
        />
        <Route
          path="vacancies"
          element={<FollowUpVacancies />}
          handle={{ name: 'breadcrumbs.VACANCIES' }}
        />
        */}
      </Route>

      <Route
        handle={{
          name: ({ auth }: any) =>
            `${t('breadcrumbs.MANAGE')} ${auth?.organisationName}`,
        }}
        path="manage/:organisationId"
        element={<Manage />}
      >
        <Route
          path="report"
          handle={{ name: 'breadcrumbs.REPORT' }}
          element={<ReportPage />}
        />
        <Route index element={<Navigate to="users" replace={true} />} />
        <Route handle={{ name: 'breadcrumbs.USERS' }} path="users">
          <Route index element={<ManageUsersTeams tab="users" />} />
          <Route
            handle={{ name: 'breadcrumbs.USER_DETAILS' }}
            path=":userId"
            element={<ManageUserDetails />}
          />
        </Route>
        <Route handle={{ name: 'breadcrumbs.TEAMS' }} path="teams">
          <Route index element={<ManageUsersTeams tab="teams" />} />
          <Route
            handle={{ name: 'breadcrumbs.TEAM_DETAILS' }}
            path=":teamId"
            element={<ManageTeamsDetails />}
          />
          <Route
            handle={{ name: 'breadcrumbs.ADD_USER' }}
            path=":teamId/addUser"
            element={<ManageAddUsersToTeam />}
          />
        </Route>
        <Route
          handle={{ name: 'breadcrumbs.ADD_USER' }}
          path="users/addUser"
          element={<ManagePageAddUsersToOrganization />}
        />
        <Route
          handle={{ name: 'breadcrumbs.ADD_USER' }}
          path="teams/addUser"
          element={<ManagePageAddUsersToOrganization />}
        />

        <Route
          handle={{ name: 'breadcrumbs.CATEGORIES' }}
          path="categories"
          element={<ManageCategories />}
        />
        <Route
          handle={{ name: 'breadcrumbs.TAGS' }}
          path="tags"
          element={<ManageTags />}
        />
        <Route
          handle={{ name: 'breadcrumbs.COMPETENCES' }}
          path="competencies"
          element={<ManageCompetencies />}
        />
        <Route
          handle={{ name: 'breadcrumbs.ADD_COMPETENCY_PROFILE' }}
          path="competencies/addCompetencyProfile"
          element={<ManagePageAddCompetencyProfile />}
        />
        <Route
          handle={{ name: 'breadcrumbs.EDIT_COMPETENCY_PROFILE' }}
          path="competencies/editCompetencyProfile/:professionId"
          element={<ManagePageEditCompetencyProfile />}
        />
        <Route
          handle={{ name: 'breadcrumbs.CERTIFICATES' }}
          path="certificates"
          element={<ManageCertificates />}
        />
      </Route>
      <Route
        path="organisation/:organisationId"
        handle={{
          name: ({ auth }: any) =>
            `${t('breadcrumbs.ORGANISATION')} ${auth?.organisationName}`,
        }}
        element={<Organisation />}
      >
        <Route index element={<Navigate to="./details" replace={true} />} />
        <Route path="create" element={<CreateOrganisation />} />
        <Route
          path="details"
          handle={{ name: 'breadcrumbs.ORGANISATION_DETAILS' }}
          element={<OrganisationDetails />}
        />
        <Route
          path="licensing-and-billing"
          handle={{ name: 'breadcrumbs.BILLING' }}
          element={<OrganisationLicensingAndBilling />}
        />
        <Route
          path="report"
          handle={{ name: 'breadcrumbs.REPORT' }}
          element={<ReportPage />}
        />
        <Route
          path="support-credits"
          element={<OrganisationSupportCredits />}
        />
        <Route
          path="branding-and-styling"
          element={<OrganisationBrandingAndStyling />}
        />
        <Route
          path="communication"
          handle={{ name: 'breadcrumbs.COMMUNICATION' }}
          element={<OrganisationCommunication />}
        />
        <Route path="api" element={<OrganisationApi />} />
      </Route>

      <Route
        path="profile"
        handle={{ name: 'breadcrumbs.PROFILE_SETTINGS' }}
        element={<Profile />}
      >
        <Route index element={<Navigate to="./details" replace={true} />} />
        <Route
          path="details"
          handle={{ name: 'breadcrumbs.PROFILE_INFORMATION' }}
          element={<ProfileDetails />}
        >
          <Route
            path="account"
            handle={{ name: 'breadcrumbs.PROFILE_INFORMATION' }}
            element={<ProfileAccount />}
          />
        </Route>
        <Route
          path="follow-up"
          handle={{ name: 'breadcrumbs.PROFILE_FOLLOW' }}
          element={<ProfileFollowUp />}
        />
        <Route
          path="emails-notifications"
          handle={{ name: 'breadcrumbs.EMAILS_NOTIFICATION' }}
          element={<EmailsAndNotifications />}
        />
        <Route
          path="email-and-password"
          element={<ProfileEmailAndPassword />}
        />
      </Route>

      <Route path="register-success" element={<RegisterSucessPage />} />
      <Route path="forgot-password" element={<AuthForgotPassword />} />
      <Route
        path="resetPasswordSuccess"
        element={<AuthResetPasswordSuccess />}
      />
      <Route path="reset" element={<SetNewPassword />} />
      <Route path="register" element={<AuthRegister />} />
      <Route path="login" element={<AuthLogin />} />
      <Route path="custom-login" element={<AuthLogin />} />

      <Route path="*" element={<Four04 />} />
      <Route path="404" element={<Four04 />} />
      <Route path="noaccess" element={<NoAccessPage />} />
    </Route>
  </>
);

/*
history.listen(() => {

  setTimeout(() => {
    // there has to be a better way to fetch this, not sure what the requirement for the virtualPagePath is
    const URLParts = window.location.href.split('/');
    URLParts.splice(0, 3);
    try {
      const { auth } = store.getState();
      dataLayer.push({
        event: 'VirtualPageview',
        organisationId: auth?.user?.activeOrganisationId,
        userId: auth?.user?.id,
        virtualPagePath: '/' + URLParts.join('/'),
        virtualPageTitle: document.title,
        virtualPageURL: window.location.href,
      });
    } catch (e) {
      console.error(e);
    }
  }, 350);

});
  */

export default routes;
