import { useState, useContext, useEffect, useRef, createRef } from 'react';
import { useGet } from '@/query/fetchHooks';
import { useParams, useNavigate } from 'react-router-dom';
import { NavContext } from '@/components/NavContext';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
import XApiLessonHeader from './XApiLessonHeader';
import XApiLessonContent from './XApiLessonContent';
import { useMediaQuery } from 'react-responsive';
import {
  mongoEncodeKey,
  newUid,
  useStateAndRef,
  nthIndexOf,
  nthLastIndexOf,
} from '@/util';
import { config } from '@/config';
import { axios } from '@/axios';
import { SidebarXApi } from '@/components/LessonTree/SidebarXApi';
import { SidebarXApiStudent } from '@/components/LessonTree/SidebarXApiStudent';
import { useTranslation } from 'react-i18next';
import { CourseSuccess } from '../Lessons/CourseSuccess';
import { Sidebar } from '@teo/components/icons';
import { IconButton } from '@teo/components';

const iFrameRef = createRef<any>();

const XApiLessonPage = ({ courseInfo }: any) => {
  const { setNavDisabled } = useContext(NavContext);
  const [activeNode, realSetActiveNode, activeNodeRef] = useStateAndRef(null);
  const [showCourseSuccess, setShowCourseSuccess] = useState(false);
  const [numLesAs, setNumLesAs] = useState<any>(1);
  const [hideSidebar, setHideSidebar] = useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const isDesctop = useMediaQuery({
    query: '(max-width: 1023px)',
  });

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  });

  const [navigateTo, setNavigateTo] = useState<null | string>(null);

  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  }, [navigateTo]);

  const lastWidgetId = 0;
  const scormWidgets: any[] = [];

  const hideScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const showScroll = () => {
    document.body.style.overflow = 'auto';
  };

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const { courseId, organisationId } = useParams();
  let { studentId } = useParams();

  let itSMe = false;
  if (!studentId) {
    itSMe = true;
    studentId = authUser?.id as unknown as string;
  }
  const isTeacher = !itSMe;
  const [student, setstudent, studentRef] = useStateAndRef(null);
  const [course, setCourse] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [initialised, setInitialised] = useState<boolean>(false);

  const basePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 2)
  );
  let typePath = course?.courseType + 's';
  if (typePath === 'leervacatures') typePath = 'vacancies';
  const returnPath = `${basePath}/${typePath}/${course?.id}`;

  const isPreview =
    window.location.pathname.includes('/preview/') || !studentId;

  const { refetch: refetchStudentInfo } = useGet(
    `/student/${courseId}/${studentId}`,
    {
      enabled: !!studentId,
      onSuccess: (result: any) => {
        setstudent(result?.data || {});
      },
    }
  );

  useGet(`/coursedetail/${courseId}`, {
    onSuccess: (result: any) => {
      setCourse(result?.data || {});
    },
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const iSsmallLaptop = useMediaQuery({
    query: '(max-width: 1199px)',
  });

  useEffect(() => {
    if (isMobile) {
      setHideSidebar(true);
    } else {
      setHideSidebar(false);
    }
  }, [isMobile]);

  const subscriptions: any[] = [];
  const loaded = false;

  const updateFollow = async (data: any) => {
    setstudent((student: any) => Object.assign({}, student, data));
    Object.assign(studentRef.current, data);
    await axios.put(
      `${config.backend}/follow/${student.courseId}/${student.userId}`,
      data
    );
  };

  if (!course) return null;

  if (showCourseSuccess) {
    return (
      <CourseSuccess
        onClose={() => setShowCourseSuccess(false)}
        studentId={authUser.id}
        course={course}
      />
    );
  }

  return (
    <div className="flex w-full flex-col lg:min-h-[calc(100vh_-_70px)]">
      <XApiLessonHeader
        returnPath={returnPath}
        student={student}
        isPreview={isPreview}
        isMobile={isMobile}
        iSsmallLaptop={iSsmallLaptop}
        courseId={courseId}
        hideSidebar={hideSidebar}
        setHideSidebar={setHideSidebar}
      />
      <div className="flex">
        {!hideSidebar && (
          <div className="left-0 flex w-full flex-col border-r border-solid border-grey-02 p-5 px-2 duration-500 ease-out md:!max-w-[380px]">
            <div className="p-5 md:sticky md:top-[80px] md:max-h-[calc(100vh_-_80px)] md:w-[380px] md:overflow-y-auto">
              <p className="mt-2 mb-4 pl-2 text-base font-medium">Content</p>
              <SidebarXApiStudent
                key={course?.id + '_' + course?.publicationPath}
                coursecontentId={course.masterId}
                publicationType={course.publicationType}
                publicationPath={course.publicationPath}
                activeNode={activeNode}
                autoInit={true}
                setNumLesAs={setNumLesAs}
                setActiveNode={realSetActiveNode}
                isDesctop={isDesctop}
              />
            </div>
          </div>
        )}
        <div>
          {/*course && <>{`${config.s3root}scorm/landing.html?id=${course.masterId || course.coursecontentd}`}</>*/}
          {course && activeNode && (
            <XApiLessonContent
              notHidden={!isMobile || hideSidebar}
              iFrameRef={iFrameRef}
              coursecontentId={course.masterId}
              courseId={course.id}
              publicationPath={course.publicationPath}
              autoInit={true}
              node={activeNode}
              onExit={() => setShowCourseSuccess(true)}
              itemId={activeNode['@_identifier']}
              width={hideSidebar ? '100vw' : 'calc(100vw - 380px)'}
            />
          )}
        </div>
      </div>
      <div data-testid="hide-sidebar" className="hidden md:block">
        <IconButton
          className="fixed left-2 bottom-[90px] z-[4] bg-white hover:bg-[#f4f4f4]"
          Icon={Sidebar}
          variant="outline"
          onClick={() => setHideSidebar(!hideSidebar)}
        />
      </div>
    </div>
  );
};

export default XApiLessonPage;
