import { useState, useEffect, useContext, createRef } from 'react';
import {
  Bell,
  BellFilled,
  EnvelopeUnread,
  CommentBox,
} from '@teo/components/icons';
import {
  Tag,
  Dropdown,
  FilterButton,
  Button,
  Notifications,
} from '@teo/components';
import { useTranslation } from 'react-i18next';
import { notificationData } from '@/mocks/notificationData';
import { Notification } from '@/models/notification.interface';
import { useGet } from '@/query';
import i18n from 'i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { NotificationUpdateContex } from '@/components/Contexts/NotificationUpdateContex';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { axios } from '@/axios';
import { config } from '@/config';
import { parseContentRange, newUid, parseCourseType } from '@/util';
import Hint from '../Hints/Hints';

const NotificationButton = ({ newPage, hints }: any) => {
  const [allData, setAllData] = useState<Array<Notification>>([]);
  const [unreadData, setUnreadData] = useState<Array<Notification>>([]);
  const [open, setOpen] = useState<boolean>(true);

  const trigger = createRef<HTMLDivElement>();

  const navigate = useNavigate();

  const { auth } = useContext(AuthContext);
  const { notificationUpdate, setNotificationUpdate } = useContext(
    NotificationUpdateContex
  );
  const [tab, setTab] = useState<string>('unread');
  const [notifications, setNotifications] = useState<any[]>([]);
  const [nrOfNotifications, setNrOfNotifications] = useState<number>(0);

  const parseNotification = (notification: any) => {
    //console.log("notification", notification)
    const parsed: any = {
      id: notification.id,
      releaseDate: notification.releaseDate,
      createdAt: notification.createdAt,
      updatedAt: notification.updatedAt,
      userId: notification.userId,
      globalLink: notification.globalLink,
      read: !notification.unread,
    };
    switch (notification?.type) {
      case 'tocorrect':
        parsed.text = (
          <>
            {t('notifications.corrections')}{' '}
            <b>{notification.text.courseName}</b>
            {notification?.text?.organisationName && (
              <>
                {' '}
                (<b>{notification?.text?.organisationName}</b>)
              </>
            )}
          </>
        );
        parsed.type = 'icon';
        parsed.actionLabel = t('notifications.to_course');
        parsed.image =
          notification.text.courseImage || '/assets/images/opleidingen.jpg';
        break;

      case 'courseadded':
      case 'coursestarted':
      case 'coursecomplete':
        parsed.text = (
          <>
            <b>{notification.text.username}</b>{' '}
            {t(
              `notifications.${
                notification.type == 'coursestarted'
                  ? 'been_start_course'
                  : notification.type == 'coursecomplete'
                  ? 'been_complet_course'
                  : 'been_add_course'
              }`
            )}{' '}
            {notification.text.courseType &&
              t(`notifications.${notification.text.courseType}`)}{' '}
            <b>{notification.text.courseName}</b>
            {notification?.text?.organisationName && (
              <>
                {' '}
                (<b>{notification?.text?.organisationName}</b>)
              </>
            )}
          </>
        );
        parsed.type = 'person';
        parsed.actionLabel = t('notifications.to_course');
        parsed.image =
          notification.text.courseImage || '/assets/images/opleidingen.jpg';
        break;
      case 'publicationupdated':
      case 'publicationtranslationsupdated':
        parsed.text = (
          <>
            <p
              dangerouslySetInnerHTML={{ __html: notification?.text?.text }}
            ></p>
            {notification?.text?.version && (
              <p>{`"${notification?.text?.version}"`}</p>
            )}
          </>
        );
        parsed.icon = CommentBox;
        parsed.type = 'icon';
        parsed.actionLabel = t('notifications.go');
        break;
      case 'lessoncorrected':
        parsed.text = (
          <>
            <p
              dangerouslySetInnerHTML={{ __html: notification?.text?.text }}
            ></p>
          </>
        );
        parsed.icon = CommentBox;
        parsed.type = 'icon';
        parsed.actionLabel = t('notifications.go');
        break;
      case 'coursecorrected':
        parsed.text = (
          <>
            <p
              dangerouslySetInnerHTML={{ __html: notification?.text?.text }}
            ></p>
          </>
        );
        parsed.type = 'person';
        parsed.image =
          notification.text.courseImage || '/assets/images/opleidingen.jpg';
        parsed.actionLabel = t('notifications.go');
        break;
      case 'newcourse':
        parsed.text = (
          <p>
            {' '}
            {t('notifications.new_course_added')}
            {': '}
            <b>{notification.text.courseName}</b>
          </p>
        );
        parsed.type = 'person';
        parsed.image =
          notification.text.courseImage || '/assets/images/opleidingen.jpg';
        parsed.actionLabel = t('notifications.go');
        break;
      default:
        parsed.text = notification?.text?.text ? (
          <p dangerouslySetInnerHTML={{ __html: notification?.text?.text }}></p>
        ) : (
          notification?.text
        );
        parsed.icon = notification?.text?.icon ? Bell : CommentBox;
        parsed.type = 'icon';
        parsed.actionLabel = t('notifications.go');
        break;
    }
    return parsed;
  };

  const parseNotifications = (notifications: any[]) => {
    return notifications.map((notification: any) => {
      return Object.assign({}, notification, {
        globalLink: notification?.globalLink
          ?.replace(
            /:organisationId/,
            notification?.text?.organisationId
              ? notification.text.organisationId
              : auth?.user?.activeOrganisationId
          )
          .replace(/:lang/, i18n.language),
      });
    });
  };

  const { refetch: refetchAllNotifications } = useGet(`/notifications`, {
    scope: 'current',
    enabled: false,
    userId: auth?.user?.id,
    count: 10,
    onSuccess: (result: any) => {
      setNotifications(parseNotifications((result?.data as any[]) || []));
    },
  });

  const { refetch: refetchUnreadNotifications } = useGet(`/notifications`, {
    scope: 'current',
    userId: auth?.user?.id,
    count: 10,
    unread: true,
    onSuccess: (result: any) => {
      setNrOfNotifications(parseContentRange(result));
      setNotifications(parseNotifications((result?.data as any[]) || []));
    },
  });

  const refetch = () => {
    if (tab === 'unread') refetchUnreadNotifications();
    else if (tab === 'all') refetchAllNotifications();
  };

  useEffect(() => {
    refetch();
  }, [tab, notificationUpdate]);

  useEffect(() => {
    if (notificationData.length > 0) {
      setAllData(notificationData);
    }
  }, []);

  useEffect(() => {
    if (notificationData.length > 0) {
      setUnreadData(
        allData?.filter((notification) => {
          return !notification.read && notification;
        })
      );
    }
  }, [allData]);

  const onClickAll = () => {
    onClickAll;
  };

  const onClickUnread = () => {
    setTab('unread');
  };

  const markAllRead = async () => {
    const promises = [];
    for (const notification of notifications) {
      promises.push(
        axios.put(`${config.backend}/notification/${notification.id}`, {
          unread: false,
        })
      );
    }
    await Promise.allSettled(promises);
    // refetch();
    setNotificationUpdate(newUid(20));
  };

  const markSingleNotificationAsRead = async (id: number) => {
    axios
      .put(`${config.backend}/notification/${id}`, {
        unread: false,
      })
      .then(() => {
        // refetch();
        setNotificationUpdate(newUid(20));
      });
  };

  const checkType = (type: string | null) => {
    switch (type) {
      case 'general':
        return 'icon';
      case 'correction':
        return 'icon';
      case 'team':
        return 'team';
      default:
        return 'person';
    }
  };

  const { t } = useTranslation();
  return (
    <div className="">
      <Dropdown>
        <Dropdown.Trigger
          className={`rounded-lg bg-grey-01 p-2 ${
            hints?.id == 'hint_04' && 'active-hint-parent active-hint-main-menu'
          }`}
        >
          {nrOfNotifications > 0 ? (
            <div ref={trigger}>
              <Hint
                key={`hint_04_${newPage}`}
                id={'hint_04'}
                leftDots={'-left-2'}
                title={t('notifications.title')}
                order={4}
                style={{
                  right: '50%',
                  top: '70px',
                }}
              />
              <BellFilled className="h-6 w-6 text-grey-07" />
              <div className="absolute -top-3 -right-1 w-5">
                <Tag
                  id="7"
                  title={
                    nrOfNotifications < 100 ? `${nrOfNotifications}` : '99+'
                  }
                  variant="tint"
                />
              </div>
            </div>
          ) : (
            <div ref={trigger}>
              <Hint
                key={`hint_04_${newPage}`}
                id={'hint_04'}
                leftDots={'-left-2'}
                title={t('notifications.title')}
                order={4}
                style={{
                  right: '50%',
                  top: '70px',
                }}
              />
              <Bell className="h-6 w-6 text-grey-07" />
            </div>
          )}
        </Dropdown.Trigger>
        <Dropdown.Content className="absolute right-0 top-full  z-[100] mt-4 w-[400px] max-w-[calc(100vw_-_24px)] rounded-lg bg-white drop-shadow-m md:z-10">
          <div className="px-4 py-3">
            <div className="text-md my-2 gap-3 text-center font-semibold">
              {t('')}
            </div>
          </div>
          <div className="flex px-4 py-3">
            <FilterButton
              active={tab === 'unread'}
              onClick={() => setTab('unread')}
              size="sm"
            >
              {t('notifications.unread')}
            </FilterButton>
            <div className="w-3"></div>
            <FilterButton active={tab === 'all'} onClick={() => setTab('all')}>
              {t('notifications.all_notifications')}
            </FilterButton>
            <Button
              variant="ghost"
              size="sm"
              className="ml-auto bg-transparent pr-0 disabled:bg-transparent"
              disabled={unreadData.length < 1}
              onClick={markAllRead}
            >
              {t('notifications.mark_all_read')}
            </Button>
          </div>
          <div className="flex max-h-[60vh] overflow-y-auto px-4 py-3">
            <Notifications>
              {notifications?.map((notification: any) => {
                const parsed = parseNotification(notification);
                return (
                  <Notifications.Item
                    key={parsed.id}
                    ActionIcon={EnvelopeUnread}
                    avatarId={String(notification?.text?.userId || 1)}
                    avatarName={notification?.text?.username || 'message'}
                    avatarImage={
                      notification?.type === 'newcourse'
                        ? notification?.text?.courseImage
                        : notification?.text?.userImage ||
                          notification?.text?.courseImage
                    }
                    Icon={parsed.icon || Bell}
                    onAction={() => {
                      markSingleNotificationAsRead(parsed.id);
                    }}
                    onClick={() => {
                      trigger.current?.click();
                      notification.globalLink &&
                        navigate(
                          notification?.type === 'newcourse'
                            ? `/${i18n.language}/learn/${
                                notification?.text?.organisationId
                                  ? notification.text.organisationId
                                  : auth?.user?.activeOrganisationId
                              }/${parseCourseType(
                                notification?.text?.courseType
                              )}/${notification?.text?.courseId}`
                            : notification.globalLink
                        );
                    }}
                    subtitle={format(
                      new Date(
                        notification?.releaseDate || notification?.createdAt
                      ),
                      'dd/MM kk:mm'
                    )}
                    title={parsed.text}
                    type={parsed.type}
                    isRead={parsed.read}
                    actionClassName={`${
                      notification?.unread ? 'bg-primary-04' : 'hidden'
                    }`}
                    iconClassName={'p-1.5 bg-primary-04 rounded-full'}
                  />
                );
              })}
            </Notifications>
          </div>
        </Dropdown.Content>
      </Dropdown>
    </div>
  );
};

export { NotificationButton };
