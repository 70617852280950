import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputText,
  Label,
  InputTextarea,
  Spinner,
  Button,
} from '@teo/components';
import { Tag } from '@teo/components/icons';
import { axios } from '@/axios';
import { isEmpty, uploadFile, newUid } from '@/util';
import { config } from '@/config';
import { useGet } from '@/query/fetchHooks';
import { AuthContext } from '@/components/Contexts/AuthContext';
import i18n from 'i18next';
import { Coursecontentdetails } from '@/models/Database';
import InfoMessage from '@/components/InfoMessage/InfoMessage';
import UploadMainImage from '@/components/UploadMainImage/UploadMainImage';
import { PaginationMultiselectWithTags } from '@/components/PaginationMultiselectWithTags';
import ExtraInfo from '@/components/ExtraInfo/ExtraInfo';
import Hint from '@/components/Hints/Hints';

const EditPublicationHomepage = ({
  coursecontentId,
  currentPublication,
  setPublication,
  directories,
  setTree,
  setAddLesson,
  setLessons,
  lessons,
  prevTree,
  setActiveNode,
  setParentNode,
  setMobileSidebar,
  setExtraInfoEdit,
  extraInfoEdit,
  isFroalaLoaded,
  hints,
  setHints,
  showWelcomeMessage,
  setShowWelcomeMessage,
  newPage,
  publicationType,
  setRefreshScorm,
  isOwner,
}: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const authUser: any = auth?.user;
  const [loadLessons, setLoadLessons] = useState<any>(true);
  const [loadingImgSpinner, setLoadingImgSpinner] = useState<any>(false);
  const [filter, setFilter] = useState<any>('');
  const [showExtraInfo, setShowExtraInfo] = useState<boolean>(false);
  const [rootDirectory, setRootDirectory] = useState<any>(null);
  const [extraInfoLessons, setExtraInfoLessons] = useState<any>([]);
  const [uploading, setUploading] = useState<null | string>(null);

  const { data, refetch } = useGet<Coursecontentdetails>(
    `/coursecontent/${coursecontentId}`,
    {
      onSuccess: (result: any) => {
        if (result?.data) {
          setLoadLessons(false);
        }
      },
    }
  );
  const publication: Coursecontentdetails | undefined = data?.data || undefined;

  const isNotTeo = !publication?.publicationType?.startsWith('teo');
  const isScorm = publication?.publicationType?.startsWith('scorm');
  const isXApi = publication?.publicationType?.startsWith('xapi');

  const basePublicationType = publication?.publicationType?.split('/')[0];

  const [name, setName] = useState<string>(publication?.name || '');
  const [image, setImage] = useState<string>(publication?.image || '');
  const [description, setDescription] = useState<string>(
    publication?.description || ''
  );

  const oldTags =
    publication?.tags?.split(';;')?.filter((x: string) => {
      return x.startsWith('tag:');
    }) || [];

  const nonTags =
    publication?.tags?.split(';;')?.filter((x: string) => {
      return !x.startsWith('tag:');
    }) || [];

  const [tags, setTags] = useState<any[]>(
    oldTags?.map((x: string) => ({
      key: x.slice(4),
      label: x.slice(4),
      tag: x.slice(4),
    })) || []
  );

  useEffect(() => {
    setRootDirectory(directories?.find((el: any) => el.path === '/'));
  }, [directories]);

  useEffect(() => {
    if (lessons) {
      setExtraInfoLessons(
        lessons?.filter(
          (les: any) => les?.rootDirectoryId === rootDirectory?.id
        )
        // lessons
      );
    }
  }, [lessons, rootDirectory]);

  useEffect(() => {
    setName(publication?.name || '');
    setDescription(publication?.description || '');
    setImage(publication?.image || '');
  }, [publication]);

  useEffect(() => {
    save();
  }, [image, tags]);

  const save = async () => {
    if (!publication) return;
    const changes: any = {};
    if (name !== publication?.name) {
      changes.name = name;
      setPublication((prev: any) => ({ ...prev, name: name }));
    }
    if (image !== publication?.image) {
      changes.image = image;
      setPublication((prev: any) => ({ ...prev, image: image }));
    }
    if (description !== publication?.description) {
      changes.description = description;
      setPublication((prev: any) => ({ ...prev, description: description }));
    }
    const promises = [];
    if (!isEmpty(changes)) {
      promises.push(
        axios.put(`${config.backend}/coursecontent/${coursecontentId}`, changes)
      );
    }
    const newTags = tags.map((x: any) => 'tag:' + x.label);
    if (newTags.join(';;') !== oldTags?.join(';;')) {
      promises.push(
        axios.post(`${config.backend}/tag/setcoursecontenttags`, {
          coursecontentId: publication?.id,
          tags: [...nonTags, ...newTags],
        })
      );
    }
    if (promises.length > 0) {
      Promise.allSettled(promises).then(() => {
        refetch();
      });
    }
  };

  if (!publication) return null;

  const handleAddTag = () => {
    axios.post(`${config.backend}/tags`, {
      tag: filter,
      label: filter,
      key: Math.floor(Math.random() * 9999999999999),
      organisationId: auth?.user?.activeOrganisationId,
      lang: auth?.user?.lang,
    });
    setTags((prev) => [
      ...prev,
      {
        key: filter,
        label: filter,
        tag: filter,
      },
    ]);
    setFilter('');
  };

  const handleActivate = () => {
    setPublication((prev: any) => ({
      ...prev,
      publicationStatus: 'concept',
    }));
    axios.put(`${config.backend}/coursecontent/${publication?.id}`, {
      id: publication?.id,
      publicationStatus: 'concept',
    });
  };

  const handleCloseWelcomeBlock = async () => {
    if (hints) {
      const lastHint = hints?.allHints?.[hints?.allHints?.length - 1];
      const lengthHints = hints?.allHints?.length;
      if (lastHint == 0) {
        const updateHints = hints?.allHints?.map((el: any, i: any) =>
          i === lengthHints - 1 ? '1' : el
        );
        const newHintFlags = updateHints.join('');
        // console.log(hints?.allHints);
        // console.log(newHintFlags);
        // console.log(updateHints);

        const promises: any = [];
        promises.push(
          axios.put(`${config.backend}/user/${authUser?.id}`, {
            hintFlags: newHintFlags,
            id: authUser?.id,
          })
        );
        await Promise.allSettled(promises).then(() => {
          setHints((prev: any) => ({
            ...prev,
            allHints: updateHints,
          }));
          setShowWelcomeMessage(false);
        });
      }
    }
  };

  return (
    <div className="flex w-full justify-center">
      <main className="max-w-6xl grow p-4">
        {currentPublication?.publicationStatus === 'disabled' && (
          <div className="my-3 flex flex-col gap-3 rounded-lg bg-error-01 p-4">
            <h3 className="text-base font-semibold text-error-05">
              {t('pages.create_edit_course.message_title_deactivate')}
            </h3>
            <p className="text-sm text-error-05">
              {t('pages.create_edit_course.message_deactivate')}
            </p>
            <div>
              <div className="group relative inline-block">
                <Button
                  variant="ghost"
                  onClick={() => handleActivate()}
                  className={`border !bg-error-04 !text-error-01 
               ${!isOwner && 'opacity-25'}
                `}
                  disabled={!isOwner}
                >
                  {t('pages.create_edit_course.activate_button')}
                </Button>
                {!isOwner && (
                  <div className="absolute left-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
                    {t('pages.create_edit_course.message_not_owner')}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {!isNotTeo && showWelcomeMessage && (
          <InfoMessage
            title={t(
              `pages.create_edit_course.type_${publicationType}.info_title_course`
            )}
            description={t(
              `pages.create_edit_course.type_${publicationType}.info_description_course`
            )}
            onClick={() => handleCloseWelcomeBlock()}
            onClickClose={() => handleCloseWelcomeBlock()}
          />
        )}
        {isNotTeo && (
          <InfoMessage
            uploading={uploading}
            bgColor="#E4F1FF"
            title={t(
              `pages.create_edit_course.type_${basePublicationType}.info_title_course`
            )}
            description={t(
              `pages.create_edit_course.type_${basePublicationType}.info_description_course`
            )}
            actionLabel={`pages.create_edit_course.type_${basePublicationType}.update_course`}
            onClick={() => {
              uploadFile().then((files) => {
                setUploading(newUid(20));
                const path = newUid(20);
                const formData = new FormData();
                formData.append('file', files[0]);
                formData.append(
                  'coursecontentId',
                  publication?.id?.toString() || ('' as string)
                );
                formData.append('publicationPath', path || ('' as string));

                let uploadUrl = '';
                switch (basePublicationType) {
                  case 'scorm':
                    uploadUrl = `${config.backend}/import/publication/scorm`;
                    break;
                  case 'xapi':
                    uploadUrl = `${config.backend}/import/publication/xapi`;
                    break;
                }

                axios
                  .post(uploadUrl, formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                  .then(() => {
                    axios
                      .put(
                        `${config.backend}/coursecontent/${coursecontentId}`,
                        {
                          publicationPath: path,
                        }
                      )
                      .then(() => {
                        setRefreshScorm(newUid(20));
                        setUploading(null);
                      });
                  });
              });
            }}
          />
        )}
        {loadLessons && (
          <div className="m-auto p-10">
            <Spinner
              ariaLabel="Loading spinner"
              className="m-auto !h-10 !w-10 border-grey-05"
            />
          </div>
        )}
        <div
          className={`${loadLessons ? 'hidden' : 'flex w-full flex-col gap-2'}`}
        >
          {/*
          <div className="my-3 rounded-lg bg-[#1e1e200d] py-2 px-3">
            <div className="text-sm font-normal text-[#4d4d50]">
              FEEDBACK
            </div>
          </div>
          */}

          <div className="mt-6 mb-6 flex">
            <div className="flex-auto pr-6">
              <InputText
                value={name}
                onChange={(ev: any) => setName(ev.target.value)}
                onBlur={save}
                customStyles="bg-white inputWhiteBg !text-2xl !text-black placeholder:text-xl"
                placeholder={t(
                  `pages.create_edit_course.type_${publicationType}.title_course_placeholder`
                )}
              />
            </div>
            {!isNotTeo && (
              <Button
                variant="secondary"
                onClick={() => setShowExtraInfo(true)}
                className={`relative ${
                  hints?.id == 'hint_42' &&
                  'active-hint-parent active-hint-main-menu'
                } ${showExtraInfo && 'z-[0]'}`}
              >
                <Hint
                  key={`hint_42_${newPage}`}
                  id={'hint_42'}
                  leftDots={'-left-2 top-[11px]'}
                  title={t('hint.title_42')}
                  order={42}
                  style={{
                    right: 'calc(100% + 20px)',
                    top: '-5px',
                  }}
                />
                {t('extra_info.title')}
                <span className="pl-1 text-grey-05">
                  ({extraInfoLessons?.length})
                </span>
              </Button>
            )}
          </div>
          <div className="flex items-center">
            <Tag className="mr-2 h-[17.5px] w-[17.5px] text-[#6d6d72]" />

            <div>
              <PaginationMultiselectWithTags
                value={tags}
                onChange={(value: any) => {
                  setTags(value);
                }}
                filter={filter}
                setFilter={setFilter}
                keyFn={(row: any) => row?.tag}
                fetch={useGet.bind(
                  null,
                  `/tags?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId},OR,organisationId=null`
                )}
                onCommitQuery={() => {
                  setFilter('');
                  handleAddTag();
                }}
                defaultSort={[{ id: 'tag', desc: false }]}
                render={(row: any) => {
                  return row?.tag;
                }}
                customStyles="bg-white"
                addTagLabel={`${filter} ${t(
                  'pages.create_edit_course.addTagLabel'
                )}`}
                handleAddTag={handleAddTag}
                placeholder={
                  tags?.length > 0
                    ? ''
                    : t('pages.create_edit_course.tags_placeholder')
                }
              />
            </div>
          </div>
          <div>
            {loadingImgSpinner && (
              <div className="mx-auto flex justify-center p-10">
                <Spinner
                  ariaLabel="Loading spinner"
                  className="!h-10 !w-10 border-grey-05"
                />
              </div>
            )}

            <UploadMainImage
              setUploadLogo={setImage}
              uploadLogo={image}
              courseImg={true}
              setLoadingImgSpinner={setLoadingImgSpinner}
              loadingImgSpinner={loadingImgSpinner}
            />
          </div>
          <div>
            <Label htmlFor="courseDescription">
              <p className="mt-6 text-sm font-semibold">
                {t(
                  `pages.create_edit_course.type_${publicationType}.course_description`
                )}
              </p>
            </Label>
            <div onBlur={() => save()} className="mt-4">
              <InputTextarea
                id="description"
                placeholder={t(
                  `pages.create_edit_course.type_${publicationType}.course_description_placeholder`
                )}
                onChange={(event) => setDescription(event.target.value)}
                value={description}
                limit={300}
                customStyles="bg-white inputWhiteBg"
              />
            </div>
          </div>
        </div>
      </main>
      {showExtraInfo && !isNotTeo && (
        <ExtraInfo
          setShowExtraInfo={setShowExtraInfo}
          activeNode={rootDirectory}
          node={rootDirectory}
          publication={publication}
          prevTree={prevTree}
          setTree={setTree}
          setAddLesson={setAddLesson}
          setLessons={setLessons}
          nameItem={name}
          extraInfoLessons={extraInfoLessons}
          setActiveNode={setActiveNode}
          setParentNode={setParentNode}
          setMobileSidebar={setMobileSidebar}
          setExtraInfoEdit={setExtraInfoEdit}
          extraInfoEdit={extraInfoEdit}
          isFroalaLoaded={isFroalaLoaded}
        />
      )}
    </div>
  );
};

export default EditPublicationHomepage;
