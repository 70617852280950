import { useGet } from '@/query/fetchHooks';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import { Button, ProgressBar, Spinner } from '@teo/components';
import { ArrowLeft, ChapterFilled, TimeFilled } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { LessonTree } from '@/components/LessonTree/LessonTree';
import { axios } from '@/axios';
import { config } from '@/config';
import { CourseInfo } from './CourseInfo';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
import { nthLastIndexOf, toPercent, round2 } from '@/util';
import { SidebarScormStudent } from '@/components/LessonTree/SidebarScormStudent';
import { SidebarXApiStudent } from '@/components/LessonTree/SidebarXApiStudent';

function CourseDetail() {
  const { courseId, organisationId } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState<any>(null);
  const [statuses, setStatuses] = useState([]);
  const [statusesAssets, setStatusesAssets] = useState([]);
  const [courseInfo, setCourseInfo] = useState<any>(null);
  const [studentCourse, setStudentCourse] = useState<any>(null);
  const [follow, setFollow] = useState<any>(null);
  const [chapters, setChapters] = useState<any>(null);
  const { t } = useTranslation();
  const { auth, setAuth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const isNotTeo = !course?.publicationType?.startsWith('teo');
  const isScorm = course?.publicationType?.startsWith('scorm');
  const isXApi = course?.publicationType?.startsWith('xapi');

  const [firstLesson, setFirsLesson] = useState<any>(null);
  const [isUserFollow, setIsUserFollow] = useState<any>(true);
  const [isError, setIsError] = useState<any>(false);
  const basePath = window.location.pathname;
  const coursePath = basePath.slice(0, basePath.lastIndexOf('/'));

  const [preloader, setPreloader] = useState(false);

  const [allExtraInfo, setAllExtraInfo] = useState(null);
  const [rootCourseId, setRootCourseId] = useState(null);
  const [allDirectories, setAllDirectories] = useState(null);
  const [allAssets, setAllAssets] = useState(null);

  const [isInstruction, setIsInstruction] = useState(false);

  const [numLesAs, setNumLesAs] = useState<any>(0);

  const originPath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 4)
  );

  useEffect(() => {
    if (isError) {
      return navigate(`${originPath}/noaccess`);
    }
  }, [isError]);

  useEffect(() => {
    if (course) {
      axios.post(config.backend + '/activity', {
        userId: auth?.user?.id,
        subjectId: course.id,
        activityType: 'learn',
        subject: course.courseName || course.name,
        logo: course.courseImage || course.image,
        link: window.location.pathname,
      });
    }
  }, [course]);

  useEffect(() => {
    if (!authUser) {
      localStorage.setItem('futurePostLoginRedirect', window.location.pathname);
      localStorage.setItem(
        'futurePostLoginOrganisation',
        organisationId as string
      );
      localStorage.setItem('futurePostLoginCourseRegister', courseId as string);
      () => {
        localStorage.removeItem('futurePostLoginRedirect');
        localStorage.removeItem('futurePostLoginOrganisation');
        localStorage.removeItem('futurePostLoginCourseRegister');
      };
    }
  }, []);

  useGet(`/lessonstatuses`, {
    courseId: courseId,
    userId: auth?.user?.id,
    onSuccess: (result: any) => {
      setStatuses(result?.data || {});
    },
  });

  useGet(`/assetstatuses`, {
    courseId: courseId,
    userId: auth?.user?.id,
    onSuccess: (result: any) => {
      setStatusesAssets(result?.data || {});
    },
  });

  useGet(`/coursedetail/${courseId}`, {
    retry: false,
    onSuccess: (result: any) => {
      setCourse(result?.data || {});
    },
    onError: (res: any) => {
      setIsError(true);
    },
  });

  useGet(`/courseinfo/${courseId}`, {
    retry: false,
    onSuccess: (result: any) => {
      setCourseInfo(result?.data || {});
    },
  });

  useGet(`/student/${courseId}/${auth?.user?.id}`, {
    retry: false,
    onSuccess: (result: any) => {
      setStudentCourse(result?.data || {});
    },
  });

  useGet(`/follow/${courseId}/${auth?.user?.id}`, {
    retry: false,
    onSuccess: (result: any) => {
      setFollow(result?.data || {});
    },
    onError: (res: any) => {
      console.error('res', res);
      setIsUserFollow(false);
    },
  });

  useEffect(() => {
    if (course) {
      setIsInstruction(course?.courseType === 'instruction');
    }
  }, [course]);

  function convertToTime(number: number) {
    const hours = Math.floor(number / 3600);
    const minutes = Math.round((number / 3600 - hours) * 60);
    return (
      hours +
      `${t('pages.learn_index.hour')} ` +
      minutes +
      `${t('pages.learn_index.minute')} `
    );
  }

  let resumePath = follow?.currentLessonId
    ? `${basePath}/lesson/${follow?.currentLessonId}`
    : `${basePath}/lesson/${firstLesson?.lessonId}`;

  if (course?.publicationType?.startsWith?.('scorm')) {
    resumePath = basePath
      .replace('courses', 'scormCourses')
      .replace('assessments', 'scormCourses')
      .replace('vacancies', 'scormCourses')
      .replace('instructions', 'scormCourses');
  } else if (course?.publicationType?.startsWith?.('xapi')) {
    resumePath = basePath
      .replace('courses', 'xApiCourses')
      .replace('assessments', 'xApiCourses')
      .replace('vacancies', 'xApiCourses')
      .replace('instructions', 'xApiCourses');
  }

  const onboardingPath = `${basePath}/onboarding`;

  const navigateLater = (url: any) => {
    setTimeout(() => {
      setPreloader(false);
      navigate(url);
    }, 0);
  };

  const [addFollow, setAddFollow] = useState({ follow: false, url: '' });

  useEffect(() => {
    if (addFollow?.follow) {
      navigateLater(addFollow?.url);
    }
  }, [addFollow?.follow]);

  const goCourse = () => {
    setPreloader(true);

    if (!follow) {
      const promises = [];
      promises.push(
        axios.post(`${config.backend}/follows`, {
          courseId: courseId,
          userId: auth?.user?.id,
          role: 20,
        })
      );
      if (course?.defaultOrganisationId) {
        promises.push(
          axios.post(`${config.backend}/members`, {
            organisationId: course?.defaultOrganisationId,
            userId: authUser?.id,
            role: 20,
          })
        );
      }

      Promise.allSettled(promises).then(() => {
        if (course?.onboardingRequired) {
          setAddFollow({ follow: true, url: onboardingPath });
        } else {
          setAddFollow({ follow: true, url: resumePath });
        }
      });
    } else {
      if (course?.onboardingRequired && !follow?.onboardingComplete) {
        return navigateLater(onboardingPath);
      } else {
        return navigateLater(resumePath);
      }
    }
  };

  const handleGoBack = () => {
    if (course?.courseType === 'assessment') {
      navigate(`${coursePath}`);
    } else {
      if (course?.courseType === 'leervacature') {
        navigate(`${coursePath}`);
      } else {
        navigate(`${coursePath}`);
      }
    }
  };

  let courseType;
  switch (course?.courseType) {
    case 'assessment':
      courseType = t('courseTypes.assessment_s');
      break;
    case 'leervacature':
      courseType = t('courseTypes.leervacature_s');
      break;
    case 'instruction':
      courseType = t('courseTypes.instruction_s');
      break;
    default:
      courseType = t('courseTypes.course_s');
      break;
  }

  let startLabel;
  switch (studentCourse?.status) {
    case 'done':
      startLabel = t('pages.course_landing.button_check_answers');
      break;
    case 'pending':
      startLabel = t('pages.course_landing.button_start', {
        courseType: courseType,
      });
      break;
    case 'active':
      startLabel = t('pages.course_landing.button_resume', {
        courseType: courseType,
      });
      break;
    case 'pendingcorrection':
      startLabel = t('pages.course_landing.button_check_answers', {
        courseType: courseType,
      });
      break;
    default:
      startLabel = t('pages.course_landing.button_register', {
        courseType: courseType,
      });
      break;
  }

  return (
    <div className={`w-full max-w-6xl flex-col ${!course && 'hidden'}`}>
      {preloader && (
        <div className="fixed inset-0 z-[1] flex bg-white/75">
          <div className="m-auto">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-16 !w-16 border-grey-08"
            />
          </div>
        </div>
      )}
      {auth && (
        <Button
          className="flex items-center gap-3"
          variant="text"
          onClick={() => handleGoBack()}
        >
          <ArrowLeft className="h-5 w-5" />
          <p>
            {course?.courseType === 'assessment'
              ? t('pages.course_landing.back_to_assessments')
              : course?.courseType === 'leervacature'
              ? t('pages.course_landing.back_to_vacancies')
              : course?.courseType === 'instruction'
              ? t('pages.course_landing.back_to_instruction')
              : t('pages.course_landing.back_to_course')}
          </p>
        </Button>
      )}
      <div className="mt-6 mb-4">
        <img
          src={
            course?.hasCustomLanding && courseInfo?.bannerPicture
              ? courseInfo?.bannerPicture
              : course?.hasCustomLanding && courseInfo?.picture
              ? courseInfo?.picture
              : course?.courseImage
              ? course?.courseImage
              : course?.image
              ? course?.image
              : '/assets/images/opleidingen.jpg'
          }
          className="max-h-80 w-full rounded-md object-cover"
        />
      </div>
      {studentCourse && !isInstruction && (
        <div className="mb-4 flex items-center">
          <ProgressBar
            max={100}
            value={
              studentCourse?.progress
                ? Math.round(studentCourse?.progress * 100)
                : 0
            }
            className={`h-1 rounded-lg ${
              studentCourse?.progress * 100 > 99 && 'progress-bar__all'
            }`}
          />
          <span className="ml-3 text-xs text-grey-06">
            {studentCourse.progress
              ? Math.round(studentCourse.progress * 100)
              : 0}
            &#37;
          </span>
        </div>
      )}
      <div>
        <h1 className="mb-4 text-xl">
          {course?.courseName || course?.name || ''}
        </h1>
        {studentCourse?.progress * 100 > 99 &&
          studentCourse?.status !== 'pendingcorrection' &&
          course?.courseType !== 'assessment' &&
          !isInstruction &&
          studentCourse?.maxScore > 0 &&
          studentCourse?.status == 'done' && (
            <div className="py-2 text-sm font-normal text-[#3257fc]">
              {t('widgets.type.final_score')}
              &nbsp;
              <span className="font-semibold">
                {toPercent(studentCourse?.score, studentCourse?.maxScore)}%
              </span>
              <span className="ml-2 font-semibold">
                {round2(studentCourse?.score)}/{round2(studentCourse?.maxScore)}
              </span>
            </div>
          )}

        {course?.courseEndDate && !isInstruction ? (
          <p className="mb-4 text-grey-05">
            {`${t('pages.learn_index.last_open')}` +
              format(new Date(course?.courseEndDate), 'dd/MM')}
          </p>
        ) : (
          ''
        )}

        <p
          className="text-sm text-grey-08 sm:text-base"
          dangerouslySetInnerHTML={{
            __html:
              course?.hasCustomLanding && courseInfo?.abstract
                ? courseInfo?.abstract
                : course?.description,
          }}
        />
      </div>
      <div className="my-4 flex items-center gap-4">
        <span className="text-sm text-grey-04">
          <ChapterFilled className="inline h-4 w-4" />
          {chapters ? (
            <span className="pr-2 text-sm text-grey-04">
              {chapters} {t('pages.course_landing.chapters')},
            </span>
          ) : null}
          {numLesAs ? numLesAs : 0} {t('pages.course_landing.lessons')}
        </span>
        {course?.totalEstimatedTime &&
        course?.totalEstimatedTime > 29 &&
        !isInstruction ? (
          <span className="text-sm text-grey-04">
            <TimeFilled className="inline h-4 w-4" />{' '}
            {convertToTime(Math.abs(course?.totalEstimatedTime))}
          </span>
        ) : null}
      </div>
      <div className="text-center sm:text-left">
        {auth ? (
          <Button
            onClick={() => {
              if (studentCourse?.status === 'done') {
                navigate(resumePath);
              } else {
                goCourse();
              }
            }}
            size="md"
            className="w-full max-w-xs first-letter:uppercase sm:w-auto"
          >
            {startLabel}
          </Button>
        ) : (
          <Button
            onClick={() => {
              localStorage.removeItem('futurePostLoginRedirect');
              localStorage.removeItem('futurePostLoginOrganisation');
              localStorage.removeItem('futurePostLoginCourseRegister');
              localStorage.setItem(
                'postLoginRedirect',
                window.location.pathname
              );
              localStorage.setItem(
                'postLoginOrganisation',
                organisationId as string
              );
              localStorage.setItem(
                'postLoginCourseRegister',
                courseId as string
              );
              navigate(originPath + '/login');
            }}
            size="md"
            className="w-full max-w-xs sm:w-auto"
          >
            {t('links.sign_in')}
          </Button>
        )}
      </div>
      <div>
        {auth && (
          <>
            <p className="mt-7 mb-4 text-base font-medium">
              {t('pages.course_landing.contents')}
            </p>
            {isScorm && (
              <SidebarScormStudent
                key={course?.id + '_' + course?.publicationPath}
                coursecontentId={course.masterId}
                publicationType={course.publicationType}
                publicationPath={course.publicationPath}
                //activeNode={activeNode}
                autoInit={true}
                setNumLesAs={setNumLesAs}
                //setActiveNode={realSetActiveNode}
                //isDesctop={isDesctop}
              />
            )}
            {isXApi && (
              <SidebarXApiStudent
                key={course?.id + '_' + course?.publicationPath}
                coursecontentId={course.masterId}
                publicationType={course.publicationType}
                publicationPath={course.publicationPath}
                //activeNode={activeNode}
                autoInit={true}
                setNumLesAs={setNumLesAs}
                //setActiveNode={realSetActiveNode}
                //isDesctop={isDesctop}
              />
            )}
            {course && !isNotTeo && (
              <LessonTree
                coursecontentId={
                  follow?.coursecontentId || course?.coursecontentId
                }
                courseId={follow?.coursecontentId ? undefined : courseId}
                userId={auth?.user?.id}
                status={statuses}
                statusAsset={statusesAssets}
                courseOrder={course?.inOrder}
                setChapters={setChapters}
                onFirstLesson={(firstLesson: any) => setFirsLesson(firstLesson)}
                rowPath={`${basePath}/lesson`}
                isUserFollow={isUserFollow}
                hideScore={course?.courseType === 'assessment'}
                setAllExtraInfo={setAllExtraInfo}
                setRootCourseId={setRootCourseId}
                setAllDirectories={setAllDirectories}
                setAllAssets={setAllAssets}
                setNumLesAs={setNumLesAs}
                isInstruction={isInstruction}
              />
            )}
          </>
        )}
        {courseInfo && <CourseInfo courseInfo={courseInfo} />}
      </div>
    </div>
  );
}

export default CourseDetail;
