import { useState, useContext, useEffect } from 'react';
import { IconButton, Dropdown, PersonAvatar, Button } from '@teo/components';
import { ArrowLeft, Sidebar } from '@teo/components/icons';
import { useGet } from '@/query/fetchHooks';
import { config } from '@/config';
import { useTranslation } from 'react-i18next';
import { useMobile, toPercent, nthIndexOf } from '@/util';
import { useNavigate } from 'react-router-dom';
import { axios } from '@/axios';
import { TinCanService } from '@/services/tincan.service';

export const CourseSuccess = ({ onClose, course, studentId }: any) => {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();

  const [student, setStudent] = useState<any>({});
  const [courseinfo, setCourseInfo] = useState<string>();
  const [passedMessage, setPassedMessage] = useState<string>();
  const [failedMessage, setFailedMessage] = useState<string>();
  const [notCorrectedMessage, setNotCorrectedMessage] = useState<string>();
  const [notCompletedMessage, setNotCompletedMessage] = useState<string>();
  const [assessmentMessage, setAssessmentMessage] = useState<string>();

  const isMobile = useMobile();
  const basePath = window.location.pathname.slice(
    0,
    nthIndexOf(window.location.pathname, '/', 4)
  );

  useEffect(() => {
    if (student.courseId) {
      axios.put(
        `${config.backend}/follow/${student.courseId}/${student.userId}`,
        {
          currentLessonId: null,
        }
      );
      const tincan = TinCanService.getInstance();
      tincan.reportStatus(student, course);
    }
  }, [student]);

  useGet(`/courseinfo/${course?.id}`, {
    enabled: !!course?.id,
    onSuccess: (result: any) => {
      setCourseInfo(result?.data);
      result?.data?.lvCompletePassed &&
        setPassedMessage(result?.data?.lvCompletePassed);
      result?.data?.lvCompleteFailed &&
        setFailedMessage(result?.data?.lvCompleteFailed);
    },
  });

  useGet(`/student/${course?.id}/${studentId}`, {
    enabled: !!course?.id && !!studentId,
    onSuccess: (result: any) => {
      setStudent(result?.data);
      !passedMessage &&
        setPassedMessage(
          t(
            'pages.course_content_flow.step_4.message_passed_default',
            result?.data
          )
        );
      !failedMessage &&
        setFailedMessage(
          t(
            'pages.course_content_flow.step_4.message_failed_default',
            result?.data
          )
        );
      !notCorrectedMessage &&
        setNotCorrectedMessage(
          t(
            'pages.course_content_flow.step_4.message_notcorrected_default',
            result?.data
          )
        );
      !notCorrectedMessage &&
        setNotCompletedMessage(
          t(
            'pages.course_content_flow.step_4.message_notcompleted_default',
            result?.data
          )
        );
      !notCorrectedMessage &&
        setAssessmentMessage(
          t(
            'pages.course_content_flow.step_4.message_assessment_default',
            result?.data
          )
        );
    },
  });

  let score = (student.score || 0) / (student.maxScore || 0);
  const isAssessment = course?.courseType === 'assessment';

  let autoCorrect = course?.correctionType !== 'manual';
  let toOverview =
    course?.correctionType === 'manual' || course?.correctionType === 'auto';

  const tincan = TinCanService.getInstance();
  tincan.reportStatus(student, course);

  let allowBack = true;
  let showCloseMessage = false;
  if (tincan.available()) {
    allowBack = false;
    showCloseMessage = true;
    toOverview = false;
  }

  if ((student.maxScore || 0) === 0) {
    score = 1;
    autoCorrect = true;
    // isAssessment = true;
  }
  const hasPassed = score >= course?.minScore;
  let incomplete = false;

  if (student?.status !== 'done') {
    incomplete = true;
  }

  let message, image;

  if (autoCorrect) {
    if (student?.status === 'done' && student?.tasksRemaining === 0) {
      if (hasPassed) {
        message = passedMessage;
        image = '/images/courseSuccess.svg';
      } else {
        message = failedMessage;
        image = '/images/courseFailure.svg';
      }
    } else {
      message = notCompletedMessage;
      image = '/images/courseSuccess.svg';
    }
  } else {
    message = notCorrectedMessage;
    image = '/images/courseSuccess.svg';
  }
  if (isAssessment) {
    message = assessmentMessage;
    image = '/images/courseSuccess.svg';
    toOverview = true;
  }

  message = message?.replace('{firstName}', student?.firstName || '');
  message = message?.replace(
    '{score_placeholder}',
    toPercent(course?.minScore).toString()
  );

  if (!student?.id) return null;

  return (
    <div className="flex min-h-screen w-full flex-col">
      <header
        className="sticky top-0 z-10 flex h-[80px] min-h-16 items-center justify-start border-b border-solid border-grey-02 bg-white p-3"
        data-testid="header"
      >
        <div data-testid="backOnCourse">
          {allowBack && (
            <IconButton
              className="my-auto mx-2"
              Icon={ArrowLeft}
              variant="outline"
              onClick={onClose}
            />
          )}
        </div>
      </header>

      <div className="flex w-full">
        <div className="mx-auto flex max-w-3xl flex-col items-center gap-8 px-4">
          <img className="mt-4 w-[300px]" src={image}></img>
          <span
            className="text-3xl-semibold"
            dangerouslySetInnerHTML={{
              __html: message as string,
            }}
          ></span>
          {incomplete && (
            <div className={'my-5 w-full rounded-lg border p-4'}>
              <div className="py-2 text-sm font-semibold">
                {t('pages.feedback.notcompleted', { courseName: course?.name })}
              </div>
              <Button
                className="my-2"
                variant="outline"
                size="md"
                onClick={() => {
                  onClose();
                  navigate(`${basePath}`);
                }}
              >
                {t('widgets.type.return_to_course')}
              </Button>
            </div>
          )}
          {!isAssessment && !incomplete && (
            <div className={'my-5 w-full rounded-lg border p-4'}>
              <div className="py-2 text-sm font-semibold">
                {t('pages.feedback.completed', { courseName: course?.name })}
              </div>
              {autoCorrect &&
                student?.status === 'done' &&
                student?.tasksRemaining === 0 && (
                  <div className="py-2 text-sm font-normal text-[#3257fc]">
                    {t('widgets.type.final_score')}
                    &nbsp;
                    <span className="font-semibold">
                      {toPercent(student?.score, student?.maxScore)}%
                    </span>
                  </div>
                )}
              {showCloseMessage && (
                <div className="py-2 text-sm font-semibold">
                  {t('pages.feedback.close')}
                </div>
              )}
              <Button
                className="my-2"
                variant="outline"
                size="md"
                onClick={() => {
                  if (toOverview) {
                    onClose();
                    navigate(`${basePath}`);
                  } else {
                    onClose();
                  }
                }}
              >
                {toOverview
                  ? t('widgets.type.to_overview')
                  : t('widgets.type.view_your_answers_button')}
              </Button>
            </div>
          )}
          {isAssessment && (
            <div className={'my-5 w-full rounded-lg border p-4'}>
              <Button
                className="my-2"
                variant="outline"
                size="md"
                onClick={() => navigate(`${basePath}`)}
              >
                {t('widgets.type.to_overview')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
