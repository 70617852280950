import { config } from '@/config';
import { ScormService } from './ScormService';
import { useState, useContext, useEffect, useRef, createRef } from 'react';

export const ScormLessonContent = ({
  node,
  iFrameRef = createRef<any>(),
  coursecontentId,
  publicationPath,
  autoInit = false,
  itemId,
  width = 'calc(100vw - 380px)',
  notHidden,
}: any) => {
  const subscriptions: any[] = [];
  const [src, setSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (autoInit) {
      subscriptions.push(
        ScormService.getInstance().Ready.subscribe(async (temp: any) => {
          if (iFrameRef.current) {
            const win = iFrameRef.current.contentWindow;
            win.postMessage({ type: 'data', data: {} }, '*');
          }
        })
      );
    }
  }, [src]);

  useEffect(() => {
    setSrc(
      `${config.s3root}scorm/landing.html?id=${coursecontentId}&path=${publicationPath}&itemId=${itemId}`
    );
  }, [itemId]);

  /*
  const adaptHeight = () => {
    let iframe = iFrameRef?.current as any;
    console.log("---------------------------setting height to", iframe.contentWindow.document.body.scrollHeight)
    iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
  }
  */

  /*
  useEffect(() => {
    if (iFrameRef?.current) {
      console.log("iframe initialised", iFrameRef?.current)
      let iframe = iFrameRef?.current as any;
      iframe.onload = function() {

      }
    }
  }, [iFrameRef]);
  */

  /*
  console.log("node", node)
  console.log("itemId", itemId)
  console.log("src", )
  */

  return (
    <iframe
      ref={iFrameRef}
      className={notHidden ? '' : '!hidden'}
      style={{
        width: width,
        minWidth: width,
        height: 'calc(100vh - 150px)',
        margin: '0',
        paddingBottom: '50px',
        border: '0',
        padding: '0',
      }}
      // @ts-ignore
      crossOrigin="anonymous" // eslint-disable-line react/no-unknown-property
      src={src}
    ></iframe>
  );
};

export default ScormLessonContent;
