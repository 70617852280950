import { decode, encode } from '@/base64';
import { MultipleChoiceEdit } from './MultipleChoiceEdit';
import { OpenQuestionEdit } from './OpenQuestionEdit';
import { MatrixEdit } from './MatrixEdit';
import { OrderVraagEdit } from './OrderEdit';
import { ConnectEdit } from './ConnectEdit';
import { BoxErrorEdit } from './BoxErrorEdit';
import { NamePartsQuestion } from './NamePartsQuestion';
import { UploadEdit } from './UploadEdit';
import { MathEdit } from './MathEdit';
import { ImageShowPartsEdit } from './ImageShowPartsEdit';
import { InvulVraagEdit } from './InvulVraagEdit';
import { BoxQuestionEditOld } from './BoxQuestionEditOld';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { Subject, BehaviorSubject, skip } from 'rxjs';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { BoxQuestionEdit } from './BoxQuestionEdit';
import { Button } from '@teo/components';
import { Trash } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { setShowNotification } from '@/App';
import { copyToClipboard, hasParentWithClass, orderWidgets } from '@/util';
import { ChatGpt } from './ChatGPT/ChatGpt';
import { WidgetOptions } from './widgetOptions/WidgetOptions';

export function getEditor(state) {
  let Edit;
  switch (state.type) {
    case 'InvulVraag':
      Edit = InvulVraagEdit;
      break;
    case 'MeerKeuze':
      Edit = MultipleChoiceEdit;
      break;
    case 'OpenVraag':
      Edit = OpenQuestionEdit;
      break;
    case 'NameImage':
    case 'NameImage2':
      Edit = NamePartsQuestion;
      break;
    case 'MatrixVraag2':
      Edit = MatrixEdit;
      break;
    case 'OrderVraag':
      Edit = OrderVraagEdit;
      break;
    case 'Link':
      Edit = ConnectEdit;
      break;
    case 'KofferVraag2':
      Edit = BoxQuestionEdit;
      break;
    case 'KofferVraag':
      Edit = BoxQuestionEditOld;
      break;
    case 'Math':
      Edit = MathEdit;
      break;
    case 'FileUpload':
      Edit = UploadEdit;
      break;
    case 'ImageShowParts':
      Edit = ImageShowPartsEdit;
      break;
    case 'BoxError':
      Edit = BoxErrorEdit;
      break;
  }
  return Edit;
}

export function renderEditor(state, target, undo, i = 0) {
  let Editor = getEditor(state);
  let placeholder = document.getElementById(state.uid);

  let hasParentWidget;
  if (placeholder) {
    placeholder.style.cssText = 'position:relative';
    placeholder.innerHTML = '';

    const parentNode = placeholder?.parentNode;
    hasParentWidget = parentNode?.closest('.widget-placeholder') !== null;
  }

  if (!Editor) {
    console.error('Error: rendering', state);
    return null;
  }

  console;

  if (
    hasParentWidget &&
    (state.type != 'Math' || state?.type != 'ImageShowParts')
  ) {
    const prevElement = placeholder.previousElementSibling;
    if (prevElement && prevElement.tagName.toLowerCase() === 'p') {
      prevElement.remove();
    }

    // placeholder.remove();
    orderWidgets();
    undo?.reset();
  }

  let widget = {
    onModified: new Subject(),
    state: state,
  };

  const handleFocus = () => {
    if (state?.type != 'Math') {
      const activeWidget = document.createElement('div');
      activeWidget.className +=
        'remove_me_gEE8QIPZ6LEay22SdSCU rounded-lg border border-secondary-04 absolute inset-0 -z-[1] active-widget';
      placeholder.appendChild(activeWidget);
    }
    //   const toolbarWidget = document.querySelector(
    //     '.fr-command[data-cmd="MeerKeuze"]'
    //   );

    //   const parentToolbar = toolbarWidget.parentNode;
    //   const allWidgetBtn = parentToolbar.querySelectorAll('button');

    //   allWidgetBtn.forEach((btn) => {
    //     btn?.classList.add('fr-disabled');
    //   });
  };
  const handleBlur = () => {
    const divsToRemove = placeholder.getElementsByClassName('active-widget');
    Array.from(divsToRemove).forEach((div) => {
      div.remove();
    });

    const toolbarWidget = document.querySelector(
      '.fr-command[data-cmd="MeerKeuze"]'
    );
    const parentToolbar = toolbarWidget.parentNode;
    const allWidgetBtn = parentToolbar.querySelectorAll('button');
    allWidgetBtn.forEach((btn) => {
      btn?.classList.remove('fr-disabled');
    });
  };

  setTimeout(() => {
    orderWidgets();
  }, 0);

  widget.portal = ReactDOM.createPortal(
    state.inline ? (
      <span>
        <Editor
          setStateFn={(fn) => (widget.stateFn = fn)}
          onModified={() => widget.onModified.next()}
          state={state}
          index={i}
        />
      </span>
    ) : (
      <div
        className={`${
          state?.type != 'Math' || state?.type != 'ImageShowParts'
            ? 'widget_practical '
            : ''
        }flex flex-row items-center`}
        onFocus={
          (state?.type != 'Math' || state?.type != 'ImageShowParts') &&
          handleFocus
        }
        onBlur={
          (state?.type != 'Math' || state?.type != 'ImageShowParts') &&
          handleBlur
        }
      >
        <Editor
          setStateFn={(fn) => (widget.stateFn = fn)}
          onModified={() => widget.onModified.next()}
          state={state}
          index={i}
        />
        <div className="flex flex-col gap-3">
          <WidgetOptions
            widget={widget}
            placeholder={placeholder}
            state={state}
            undo={undo}
            chatGPT={
              state?.type == 'MeerKeuze' ||
              state?.type == 'OpenVraag' ||
              state?.type == 'Link' ||
              state?.type == 'OrderVraag'
            }
          />
        </div>
      </div>
    ),
    placeholder
  );

  return widget;
}

export function renderChatGpt(chatUid, node, chatContent, reload) {
  node.innerHTML = '';

  let chatGpt = {
    state: chatUid,
  };

  chatGpt.portal = ReactDOM.createPortal(
    <div>
      <ChatGpt
        reload={reload}
        chatUid={chatUid.uid}
        chatContent={chatContent}
      />
    </div>,
    node
  );

  return chatGpt;
}

export function renderEditors(parent, undo, correction, waitForResult) {
  if (!parent) console.error('Parent missing', parent);

  const widgets = parent.querySelectorAll('.widget-placeholder');
  let index = 1;

  return [...widgets].reduce((acc, widget, i) => {
    if (widget.state) return acc; //renderWidget(widget.state, widget, i + 1);
    let node = widget.querySelectorAll('div[data-state],span[data-state]');

    if (node.length === 0) return acc;
    let stateStr = node[0].getAttribute('data-state');

    try {
      widget.state = JSON.parse(stateStr); //legacy parsing
    } catch (e) {
      widget.state = JSON.parse(decode(stateStr)); //base64 parsing
    }
    acc[widget.state.uid] = renderEditor(widget.state, widget, undo, index);
    if (!widget.state.noSubmit) index += 1; //no submit widgets do not count towards index

    //if (!acc[widget.state.uid]) console.warn("Something went wrong rendering", widget.state)
    return acc;
  }, {});
}

export function rerenderEditors(parent, undo, oldState, oldChats) {
  if (!parent) console.error('Parent missing', parent);
  const widgets = parent.getElementsByClassName('widget-placeholder');
  const chats = parent.getElementsByClassName('chat-gpt-placeholder');
  let index = 1;
  let stateChat = [];
  let stateWidget = [];
  if (oldState.length > 0) {
    const widgetsArr = [...widgets].filter(
      (widget, i) => !hasParentWithClass(widget, 'widget-placeholder')
    );
    widgetsArr.map((widget, i) => {
      // if (widget.state) return acc; //renderWidget(widget.state, widget, i + 1);
      widget.state = oldState.find((el) => el.uid === widget.id);
      const el = renderEditor(widget.state, widget, undo, index);
      if (!widget.state.noSubmit) index += 1;
      stateWidget.push(el);
    }, {});
  }
  [...chats].map((chat, i) => {
    chat.state = oldChats.find((el) => el.uid === chat.id);
    if (chat.state && !chat.classList.contains('close-chat')) {
      const chatResultat = chat.querySelectorAll('.chat_resultat');
      const prevResultat = chatResultat[0]?.childNodes[0];
      const el = renderChatGpt(chat.state, chat, prevResultat, true);
      stateChat.push(el);
    }
  }, {});
  return { stateWidget, stateChat };
}
