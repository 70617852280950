import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { publicAxios } from '@/axios';
import { config } from '@/config';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { PageUrlContext } from '@/components/Contexts/PageUrlContext';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { XMLParser } from 'fast-xml-parser';
import i18n from 'i18next';
import { getLocalizedText, walkObject } from '@/util';

export const SidebarXApiStudent = ({
  coursecontentId,
  publicationType,
  publicationPath,
  activeNode,
  autoInit,
  setActiveNode,
  isDesctop,
  setNumLesAs,
}: any) => {
  const { t } = useTranslation();
  const [lessons, setLessons] = useState<any[]>([]);
  const { hints } = useContext(HintsContext);
  const { newPage } = useContext(PageUrlContext);
  const { setShowNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (coursecontentId) {
      publicAxios
        .get(
          `${config.s3root}xapi/${coursecontentId}/${publicationPath}/cmi5.xml`
        )
        .then((result) => {
          const parser = new XMLParser({
            ignoreAttributes: false,
            isArray: (name: string) => ['au'].includes(name),
          });
          const json = parser.parse(result.data);
          let lessons: any[] = [];

          walkObject(json, (el: any, key: string) => {
            if (key === 'au') {
              lessons.push(...el);
            }
          });

          lessons = lessons.map((au: any, index: number) => ({
            id: au['@_id'],
            title:
              getLocalizedText(
                au.title?.langstring || au.title,
                i18n.language
              ) || `Lesson ${index + 1}`,
            description:
              getLocalizedText(
                au.description?.langstring || au.description,
                i18n.language
              ) || '',
            url: au.url,
            moveOn: au['@_moveOn'],
          }));

          setLessons(lessons);
          setNumLesAs && setNumLesAs(lessons.length);
          if (autoInit && lessons.length > 0) {
            setActiveNode && setActiveNode(lessons[0]);
          }
        })
        .catch((err) => console.error('Failed to load cmi5 manifest:', err));
    }
  }, [coursecontentId]);

  return (
    <>
      {lessons.map((item, i) => (
        <div
          key={item.id}
          onClick={() => setActiveNode && setActiveNode(item)}
          className={`
            ${activeNode?.id === item.id ? 'bg-primary-03' : ''}
            my-1 flex items-center justify-between
          `}
        >
          <div className="flex min-h-[40px] w-full items-center">
            <div
              className="flex w-full cursor-pointer items-center rounded-md p-1 sm:p-2 sm:hover:bg-grey-transparent-01"
              data-testid="chapter"
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="m-2 ml-1 mr-3 w-6 text-success-05 sm:ml-2 sm:mr-5"
              >
                <path
                  d="M12 2.25c-5.376 0-9.75 4.374-9.75 9.75s4.374 9.75 9.75 9.75 9.75-4.374 9.75-9.75S17.376 2.25 12 2.25Zm5.074 6.482-6.3 7.5a.748.748 0 0 1-.562.268h-.013a.75.75 0 0 1-.557-.248l-2.7-3a.751.751 0 0 1 .88-1.186c.09.045.17.107.234.182l2.123 2.36 5.747-6.84a.75.75 0 0 1 1.148.964Z"
                  fill="currentColor"
                ></path>
              </svg>
              <p className="false w-full flex-1 pr-2 text-xs font-medium text-black sm:text-sm">
                <span className="pr-2 sm:pr-4">{i + 1}.</span>
                {item.title}
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
