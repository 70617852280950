import { useGet } from '@/query/fetchHooks';
import { useNavigate, useParams } from 'react-router-dom';
import React, { Fragment, useState, useContext, useEffect } from 'react';
import {
  PersonAvatar,
  PageHeader,
  Button,
  IconButton,
  Tabs,
  Tag,
  InputText,
  Spinner,
  Dropdown,
} from '@teo/components';
import {
  ArrowRight,
  CrossFilled,
  CommentBox,
  CommentBoxFilled,
  Search,
  Share,
} from '@teo/components/icons';
import { DataTable } from '@/components/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import { Studentwithteams, Userwithorganisationwithidps } from '@/models';
import { format } from 'date-fns';
import OptionsAction from '@/components/DataTable/OptionsAction';
import { TeamsTable } from '@/components/TeamsTable';
import StepsComponent from '@/pages/Protected/FollowUp/AddCourse/StepsComponent';
import { NavContext } from '@/components/NavContext';
import { axios, publicAxios } from '@/axios';
import { config } from '@/config';
import {
  nthLastIndexOf,
  round2,
  toPercent,
  newUid,
  downloadLink,
  downloadPDFLink,
} from '@/util';
import i18n from 'i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import RedoModal from '@/components/RedoModal';
import OpenCommentModal from '@/pages/Protected/FollowUp/OpenCommentModal/OpenCommentModal';
import QRModal from '@/components/QRModal/QRModal';
import ChoiceModal from '@/components/ChoiceModal';

function FollowUpTeamCourseDetails() {
  const { teamId, courseId, organisationId } = useParams();
  const [course, setCourse] = useState<any>(null);
  const [courseInfo, setCourseInfo] = useState<any>(null);
  const [resetUserTable, setResetUserTable] = useState<string>(newUid(20));
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;
  const { setShowNotification } = useContext(NotificationContext);
  const [isOpenRedoModal, setIsOpenRedoModal] = useState(false);
  const [usrSelected, setUsrSelected] = useState<any>();
  const [isOpenCommentModal, setIsOpenCommentModal] = useState(false);
  const [usr, setUsr] = useState<any>();
  const [userInpFilter, setUserInpFilter] = useState('');
  const [preloader, setPreloader] = useState(false);
  const [isOpenQRModal, setIsOpenQRModal] = useState(false);
  const [publicationType, setPublicationType] = useState('course');
  const [allStudents, setAllStudents] = useState([]);

  const isNotTeo = !course?.publicationType?.startsWith('teo');
  const isScorm = course?.publicationType?.startsWith('scorm');
  const isXApi = course?.publicationType?.startsWith('xapi');

  const { refetch: refetchInfo } = useGet<any>(`/courseinfo/${course?.id}`, {
    enabled: !!course?.id,
    onSuccess: (result: any) => {
      setCourseInfo(result?.data);
    },
  });
  const exportTincan = () => {
    downloadLink(
      `${config.backend}/export/course/tincan?courseId=${courseId}&organisationId=${organisationId}&lang=${i18n.language}&jwt=${auth?.token}`
    );
  };
  const exportCmi5 = () => {
    downloadLink(
      `${config.backend}/export/course/cmi5?courseId=${courseId}&organisationId=${organisationId}&lang=${i18n.language}&jwt=${auth?.token}`
    );
  };
  const exportScorm = () => {
    downloadLink(
      `${config.backend}/export/course/remotescorm?courseId=${courseId}&organisationId=${organisationId}&lang=${i18n.language}&jwt=${auth?.token}`
    );
  };

  const [shareSettings, setShareSettings] = useState<boolean>(false);
  const [isFollow, setIsFollow] = useState(false);

  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [tab, setTab] = useState('users');

  const [exportChoiceModalOpen, setExportChoiceModalOpen] = useState(false);

  const [selected, setSelected] = useState<Set<string | number>>(
    new Set<string | number>()
  );
  const [deselect, setDeselect] = useState<boolean>(false);

  const { setNavDisabled } = useContext(NavContext);

  const navigate = useNavigate();

  const basePath = window.location.pathname.slice(
    0,
    nthLastIndexOf(window.location.pathname, '/', 2)
  );

  const correctionBasePath = `/${i18n.language}/follow-up/${authUser?.activeOrganisationId}/correction`;

  //const userPath = basePath + '/users';
  const userPath = `/${i18n.language}/follow-up/${organisationId}/users`;
  const managePath = `/${i18n.language}/manage/${organisationId}/users`;
  const teamPath = `/${i18n.language}/follow-up/${organisationId}/teams`;

  const { refetch } = useGet(`/coursedetail/${courseId}`, {
    teamId,
    onSuccess: (result: any) => {
      setCourse(result?.data || null);
      setPublicationType(result?.data?.publicationCourseType || 'course');
    },
  });

  useGet(`/trackcoursedetails`, {
    organisationId: organisationId,
    userId: auth?.user?.id,
    courseId: courseId,
    onSuccess: (result: any) => {
      result && result?.data?.length > 0 && setIsFollow(true);
    },
  });

  //TODO: This call is not recommended, you should not have to fetch all
  //students, not even with multiselect.
  useGet((authUser?.role || 0) >= 90 ? '/studentswithteam' : '/mystudents', {
    userId: (authUser?.role || 0) >= 90 ? undefined : authUser?.id,
    organisationId:
      (authUser?.role || 0) >= 90 ? undefined : authUser?.activeOrganisationId,
    courseId: courseId,
    onSuccess: (result: any) => {
      setAllStudents(result?.data || []);
    },
  });

  useEffect(() => {
    // console.log(shareSettings);
    if (!shareSettings) {
      // document.body.style.overflow = 'unset';
    }
  }, [shareSettings]);

  const handleReminder = async () => {
    if (deselect) {
      const filteredIds = allStudents
        ?.filter((obj: any) => !selected.has(obj.id))
        ?.map((obj: any) => obj.id);

      for (const userId of filteredIds) {
        await axios.post(`${config.backend}/invite/reminder`, {
          courseId: course?.id,
          userId: userId,
        });
      }
    } else {
      for (const userId of selected) {
        await axios.post(`${config.backend}/invite/reminder`, {
          courseId: course?.id,
          userId: userId,
        });
      }
    }
  };

  if (shareSettings) {
    //opens the settings with only the second step loaded
    return (
      <div className="fixed top-0 left-0 z-[100] h-screen w-screen overflow-y-auto bg-white">
        {preloader && (
          <div className="fixed inset-0 z-[13] flex bg-white/75">
            <div className="m-auto">
              <Spinner
                ariaLabel="Loading spinner"
                className="!h-16 !w-16 border-grey-08"
              />
            </div>
          </div>
        )}
        <div className="flex min-h-20 flex-col justify-start py-2 pr-2 sm:flex-row sm:items-center">
          <div
            data-testid="clouseBtn"
            className="flex min-h-20 items-center justify-start"
          >
            <IconButton
              className="my-auto mx-3 sm:mx-6"
              Icon={CrossFilled}
              variant="ghost"
              onClick={() => {
                setShareSettings(false);
              }}
            />
            <div className="flex items-center">
              <div className="mr-5 w-20 min-w-20">
                <img
                  src={
                    course?.courseImage ||
                    course?.image ||
                    '/images/courseFallback.png'
                  }
                  alt="courseFallback"
                  className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                />
              </div>
              <div className="flex flex-wrap">
                <span className="mr-2 font-semibold">
                  {course?.courseName || course?.name}
                </span>
                <Tag
                  variant="error"
                  title={t('pages.follow_up_teams.status_ongoing')}
                ></Tag>
              </div>
            </div>
          </div>
        </div>
        <StepsComponent
          course={course}
          courseInfo={courseInfo}
          activeCourse={course}
          activeSteps={[1, 2, 3]}
          submitButtonLabel={'button.save'}
          onClose={() => {
            setShareSettings(false);
          }}
          onSubmit={() => {
            refetch();
            refetchInfo();
            setPreloader(false);
            setShareSettings(false);
          }}
          setLoader={setPreloader}
          publicationType={publicationType}
        />
      </div>
    );
  }

  let pageDetail: any = undefined;

  if (
    Math.max(authUser?.role || 0, authUser?.activeOrganisationRole || 0) >= 90
  ) {
    pageDetail = {
      title: t('pages.follow_up_courses.manage_user'),
      rowPath: (usr: any) => `${userPath}/${usr.id}`,
    };
  }

  const exportOptions = [
    {
      value: 'cmi5',
      label: `${t('export.xapi')} (Cmi5)`,
    },
    {
      value: 'tincan',
      label: `${t('export.xapi')} (Tin Can)`,
    },
    {
      value: 'remotescorm',
      label: `${t('export.remote_scorm')}`,
    },
  ];

  if (!courseInfo || !course) return null;

  return (
    <div className="mb-8 w-full max-w-6xl flex-col">
      {exportChoiceModalOpen && (
        <ChoiceModal
          options={exportOptions}
          closeModal={() => setExportChoiceModalOpen(false)}
          confirmLabel={t('button.download')}
          title={t('export_course.title')}
          heading={t('export_course.heading')}
          text={t('export_course.text')}
          label={t('export_course.label')}
          selectFn={(selectedValue: string) => {
            setExportChoiceModalOpen(false);
            switch (selectedValue) {
              case 'cmi5':
                return exportCmi5();
              case 'tincan':
                return exportTincan();
              case 'remotescorm':
                return exportScorm();
            }
          }}
        />
      )}
      <div className="pb-2">
        <PageHeader /* @ts-expect-error eslint-disable-next-line */
          to={-1}
          ariaLabel={'placeholder'}
        >
          <div className="flex w-full items-center py-4 lg:py-6">
            <div className="flex items-center">
              <div className="w-16 min-w-16 lg:w-24 lg:min-w-24">
                <img
                  src={
                    course?.courseImage ||
                    course?.image ||
                    '/images/courseFallback.png'
                  }
                  alt="courseFallback"
                  className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                />
              </div>
            </div>

            <div className="mx-4 flex w-full flex-col justify-between gap-4 xl:flex-row">
              <div className="flex flex-[1] flex-col content-center">
                <div className="flex flex-row flex-wrap items-center pb-1.5">
                  <h1 className="mr-3 break-all text-base font-semibold text-black md:text-lg lg:text-xl">
                    {course?.courseName || course?.name}
                  </h1>
                  <Tag
                    title={
                      course?.courseStatus === 'active'
                        ? t('pages.follow_up_teams.status_ongoing')
                        : t('pages.follow_up_teams.status_closed')
                    }
                    variant={
                      course?.courseStatus === 'active' ? 'success' : 'default'
                    }
                  />
                </div>
                <div className="flex flex-wrap content-center text-xs text-[#919193]">
                  <p className="mr-2 border-r border-[#919193] pr-2 ">
                    {course?.courseType}
                  </p>
                  <p className="mr-2 border-r border-[#919193] pr-2 ">
                    {t('columns.version')} {course?.version}
                  </p>
                  <p className="mr-2 pr-2 ">{course?.name}</p>
                </div>
              </div>

              <div className="flex flex-wrap items-center gap-3">
                <div>
                  <Button
                    variant="outline"
                    className="!p-2 text-[#7d7d81]"
                    onClick={() => setExportChoiceModalOpen(true)}
                  >
                    <img
                      src="/images/icons/export.svg"
                      alt="export button"
                      className="w-5"
                    />
                  </Button>
                  {/*
                  <Dropdown>
                    <Dropdown.Trigger>
                      <Button variant="outline" className="!p-2 text-[#7d7d81]">
                        <img
                          src="/images/icons/export.svg"
                          alt="export button"
                          className="w-5"
                        />
                      </Button>
                    </Dropdown.Trigger>
                    <Dropdown.Content>
                      <Dropdown.Actions>
                        <Dropdown.Button onClick={() => exportCmi5()}>
                          {t('export.xapi')} (Cmi5)
                        </Dropdown.Button>
                        <Dropdown.Button onClick={() => exportTincan()}>
                          {t('export.xapi')} (Tin Can)
                        </Dropdown.Button>
                        <Dropdown.Button onClick={() => exportScorm()}>
                          {t('export.remote_scorm')}
                        </Dropdown.Button>
                      </Dropdown.Actions>
                    </Dropdown.Content>
                  </Dropdown>
                  */}
                  {/* 
                  <Button
                    variant="outline"
                    className="!p-2 text-[#7d7d81]"
                    onClick={() => exportXapi()}
                  >
                    <img
                      src="/images/icons/export.svg"
                      alt="export button"
                      className="w-5"
                    />
                  </Button>
                */}
                </div>
                <div>
                  <Button
                    variant="outline"
                    className="!p-2 text-[#7d7d81]"
                    onClick={() => setIsOpenQRModal(true)}
                  >
                    <img
                      src="/images/icons/qr-code.svg"
                      alt="delete button"
                      className="w-5"
                    />
                  </Button>
                </div>
                <div className="">
                  <Button
                    variant="outline"
                    size="md"
                    containsOnlyIcon={true}
                    onClick={async () => {
                      if (isFollow) {
                        await axios.delete(
                          `${config.backend}/trackcourse/${auth?.user?.id}/${courseId}`
                        );
                        setIsFollow(!isFollow);
                      } else {
                        await axios.post(`${config.backend}/trackcourses`, {
                          userId: auth?.user?.id,
                          courseId: courseId,
                        });
                        setIsFollow(!isFollow);
                      }
                    }}
                  >
                    {isFollow ? (
                      <img
                        src="/images/components/follow_up_courses/star_icon.svg"
                        alt="star_icon"
                        className="h-5"
                      />
                    ) : (
                      <img
                        src="/images/components/follow_up_courses/star_white.svg"
                        alt="star_icon"
                        className="h-5"
                      />
                    )}
                  </Button>
                </div>

                <div className="rounded-lg border border-grey-02">
                  {course?.courseStatus !== 'disabled' && (
                    <Button
                      variant="destruct"
                      size="md"
                      className="flex items-center"
                      onClick={async () => {
                        const result = await axios.put(
                          `${config.backend}/course/${course.id}`,
                          {
                            courseStatus: 'disabled',
                          }
                        );
                        setCourse(Object.assign({}, course, result?.data));
                      }}
                    >
                      {course?.courseType == 'assessment'
                        ? t('pages.follow_up_teams.close_assessment')
                        : course?.courseType == 'leervacature'
                        ? t('pages.follow_up_teams.close_vacancy')
                        : course?.courseType == 'instruction'
                        ? t('pages.follow_up_teams.close_instruction')
                        : t('pages.follow_up_teams.close_course')}

                      <ArrowRight className="ml-2 h-5 w-5" />
                    </Button>
                  )}
                  {course?.courseStatus == 'disabled' && (
                    <Button
                      //variant="destruct"
                      size="md"
                      className="flex items-center"
                      onClick={async () => {
                        const result = await axios.put(
                          `${config.backend}/course/${course.id}`,
                          {
                            courseStatus: 'active',
                          }
                        );
                        setCourse(Object.assign({}, course, result?.data));
                      }}
                    >
                      {course?.courseType == 'assessment'
                        ? t('pages.follow_up_teams.open_assessment')
                        : course?.courseType == 'leervacature'
                        ? t('pages.follow_up_teams.open_vacancy')
                        : course?.courseType == 'instruction'
                        ? t('pages.follow_up_teams.open_instruction')
                        : t('pages.follow_up_teams.open_course')}
                      <ArrowRight className="ml-2 h-5 w-5" />
                    </Button>
                  )}
                </div>

                {/* <div>
                  <IconButton
                    Icon={MoreVertical}
                    variant="ghost"
                    className="text-[#919193]"
                    onClick={() => {
                      navigate(`${basePath}/${course?.id}/settings`);
                    }}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </PageHeader>
        <div className="mb-6 flex flex-wrap justify-between gap-3 rounded-lg border border-grey-02 p-5 sm:py-7 sm:px-9">
          <div className="flex w-full flex-row items-center gap-3">
            <div className="flex flex-col gap-3 sm:flex-row">
              <h4 className="text-base">
                {t('pages.follow_up_teams.share_settings')}
              </h4>
              {/*<Tag id="1" title="Onderhoud groep A" variant="tint" /> should be list of teams if implemented*/}
            </div>

            <div className="ml-auto">
              <Button
                variant="text"
                onClick={() => {
                  setShareSettings(true);
                }}
              >
                {t('pages.follow_up_teams.change_share_settings')}
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-3 lg:flex-row lg:gap-8">
            <div className="flex flex-col gap-2">
              <p className="flex text-sm text-grey-08">
                <span className="w-48">{t('columns.start_date')}</span>
                <span className="text-grey-06">
                  {course?.courseStartDate
                    ? format(new Date(course?.courseStartDate), 'dd/MM/yyyy')
                    : ''}
                </span>
              </p>
              <p className="flex text-sm text-grey-08">
                <span className="w-48">{t('columns.end_date')}</span>
                <span className="text-grey-06">
                  {course?.courseEndDate
                    ? format(new Date(course?.courseEndDate), 'dd/MM/yyyy')
                    : t('pages.follow_up_teams.not_finished_yet')}
                </span>
              </p>
              {!isNotTeo && course?.courseType !== 'instruction' && (
                <p className="flex text-sm text-grey-08">
                  <span className="w-48">
                    {t('pages.follow_up_teams.follow_sequentially')}
                  </span>
                  <span className="text-grey-06">
                    {course?.inOrder
                      ? t('pages.follow_up_teams.yes')
                      : t('pages.follow_up_teams.no')}
                  </span>
                </p>
              )}
              {!isNotTeo && course?.courseType !== 'instruction' && (
                <p className="flex text-sm text-grey-08">
                  <span className="w-48">
                    {t('pages.follow_up_teams.correction')}
                  </span>
                  <span className="capitalize text-grey-06">
                    {course?.correctionType &&
                      t(`pages.follow_up_teams.${course?.correctionType}`)}
                  </span>
                </p>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <p className="flex text-sm text-grey-08">
                <span className="w-48">
                  {t('pages.follow_up_teams.obligation')}
                </span>
                <span className="text-grey-06">__</span>
              </p>
              <p className="flex text-sm text-grey-08">
                <span className="w-48">
                  {t('pages.follow_up_teams.access')}
                </span>
                <span className="capitalize text-grey-06">
                  {course?.courseAccess &&
                    t(`pages.follow_up_teams.${course?.courseAccess}`)}
                </span>
              </p>
              {course?.courseType !== 'instruction' && (
                <p className="flex text-sm text-grey-08">
                  <span className="w-48">
                    {t('pages.follow_up_teams.minimale_score')}
                  </span>
                  <span className="text-grey-06">
                    {Math.round(course?.minScore * 100)}%
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="my-6 flex items-center font-medium">
        <Tabs activeName={tab} onChange={(tab) => setTab(tab)}>
          <>
            <Tabs.TabButton name="users">
              <span>{t('Users')}</span>
              <span className="ml-2 text-base text-[#919193]">
                {course?.nrOfStudents}
              </span>
              {course?.tasksRemaining > 0 && (
                <span className="ml-2 h-2 w-2 rounded-full bg-[#fc6232]"></span>
              )}
            </Tabs.TabButton>
            <Tabs.TabButton name="teams">
              <span>{t('Teams')}</span>
              <span className="ml-2 text-base text-[#919193]">
                {course?.nrOfTeamsInCourse}
              </span>
            </Tabs.TabButton>
            {course?.courseType !== 'instruction' && (
              <Tabs.TabButton name="teachers">
                <span>{t('Teachers')}</span>
                <span className="ml-2 text-base text-[#919193]">
                  {course?.nrOfTeachers}
                </span>
              </Tabs.TabButton>
            )}
          </>
        </Tabs>
      </div>
      {tab === 'teams' && (
        <TeamsTable
          fetch={useGet.bind(null, '/courseteamdetails', {
            courseId: courseId,
          })}
          rowPath={(team: any) => `${teamPath}/${team.id}`}
          organisationId={organisationId}
          empty={{
            template: 1,
            text: t('empty_tenplate.team_list'),
            btn_text: t('empty_tenplate.btn_add_team'),
            btnClick: () => setShareSettings(true),
          }}
          teameCourse={true}
          courseId={courseId}
          refetch={() => refetch()}
        />
      )}
      {tab === 'users' && (
        <Fragment>
          <div className="flex w-full flex-col justify-between lg:flex-row lg:items-center">
            <div className="pb-4 pt-2 md:w-1/2 xl:w-1/3">
              <InputText
                value={userInpFilter}
                onChange={(ev) => setUserInpFilter(ev.target.value)}
                Icon={Search}
                placeholder={t('search.user')}
              />
            </div>
            {(selected?.size > 0 || deselect) && (
              <div className="mt-6 flex flex-row lg:mt-0">
                <Button
                  onClick={() => handleReminder()}
                  size="sm"
                  disabled={selected?.size < 1 && !deselect}
                  variant="outline"
                  className="!text-error-04"
                >
                  {t('pages.follow_up_users.send_reminder')}
                </Button>
              </div>
            )}
          </div>
          <DataTable
            key={resetUserTable}
            filter={userInpFilter}
            rowPath={(user) => `${userPath}/${user.id}`}
            selectable={{
              selected: selected,
              setSelected: setSelected,
              deselect: deselect,
              setDeselect: setDeselect,
              checkboxIconMobileType: 'circle',
            }}
            defaultSort={[
              { id: 'tasksRemaining', desc: true },
              { id: 'username', desc: false },
              { id: 'createdAt', desc: false },
              { id: 'startDate', desc: false },
              { id: 'updatedAt', desc: false },
              { id: 'progress', desc: false },
              { id: 'score', desc: false },
            ]}
            fetch={useGet.bind(
              null,
              (authUser?.role || 0) >= 90 ? '/studentswithteam' : '/mystudents',
              {
                userId: (authUser?.role || 0) >= 90 ? undefined : authUser?.id,
                organisationId:
                  (authUser?.role || 0) >= 90
                    ? undefined
                    : authUser?.activeOrganisationId,
                courseId: courseId,
              }
            )}
            empty={{
              template: 1,
              text: t('empty_tenplate.user_list'),
              btn_text: t('empty_tenplate.btn_add_user'),
              btnClick: () => setShareSettings(true),
            }}
            /*
            fetch={useGet.bind(null, '/studentswithteam', {
              courseId: courseId,
            })}
            fetch={useGet.bind(null, '/mystudents', {
              userId: authUser?.id,
              organisationId: authUser?.activeOrganisationId,
              courseId: courseId,
            })}
            */
            screensUpdateTable="desktop"
            columns={[
              {
                title: t('columns.username'),
                id: 'username',
                className: 'col-start-1 col-end-9 row-start-1',
                render: (usr: Studentwithteams) => (
                  <div className="flex items-center">
                    <PersonAvatar
                      inline
                      id={usr.id}
                      name={usr.username || ''}
                      imageSrc={usr.picture || undefined}
                    ></PersonAvatar>
                    <div>
                      <div
                        className={`overflow-hidden text-ellipsis text-sm font-medium ${
                          i18n.language === 'fr'
                            ? 'max-w-[135px]'
                            : 'max-w-[180px]'
                        }`}
                      >
                        {usr.username}
                      </div>
                      <div
                        className={`max-w-[180px] overflow-hidden text-ellipsis text-xs text-[#7d7d81] ${
                          i18n.language === 'fr'
                            ? 'max-w-[135px]'
                            : 'max-w-[180px]'
                        }`}
                      >
                        {usr.email}
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                addTitleOnMobile: true,
                className: 'col-start-1 col-end-4 row-start-2',
                id: 'createdAt',
                title: t('columns.added'),
                align: 'right',
                render: (usr: Studentwithteams) =>
                  usr.createdAt
                    ? format(new Date(usr.createdAt || ''), 'dd/MM/yyyy')
                    : '',
              },
              {
                addTitleOnMobile: true,
                id: 'startDate',
                title: t('columns.start_date'),
                align: 'right',
                className:
                  'col-start-4 col-end-7 row-start-2 justify-center text-center',
                hidden: course?.courseType === 'instruction',
                render: (usr: Studentwithteams) =>
                  usr.startDate
                    ? format(new Date(usr.startDate || ''), 'dd/MM/yyyy')
                    : '',
              },
              {
                addTitleOnMobile: true,
                id: 'updatedAt',
                title: t('columns.last_active'),
                align: 'right',
                className:
                  'col-start-7 col-end-10 row-start-2 text-right justify-end',
                render: (usr: Studentwithteams) =>
                  usr.updatedAt
                    ? format(new Date(usr.updatedAt || ''), 'dd/MM/yyyy')
                    : '',
              },
              {
                addTitleOnMobile: true,
                className: 'col-start-1 col-end-4 row-start-3',
                id: 'progress',
                align: 'right',
                title: t('columns.progress'),
                hidden: course?.courseType === 'instruction',
                render: (usr: any) => (
                  <div>{Math.round(usr.progress * 100)}%</div>
                ),
              },
              {
                addTitleOnMobile: true,
                className:
                  'col-start-4 col-end-7 row-start-3 justify-center text-center',
                id: 'scoreRatio',
                align: 'right',
                title: t('columns.score'),
                hidden: course?.courseType === 'instruction',
                render: (usr: any) => (
                  <div>
                    {!usr?.maxScore ? (
                      <Tag
                        size="sm"
                        variant="success"
                        title={t('pages.follow_up_courses.score_status')}
                      ></Tag>
                    ) : (
                      <span
                        className={`${
                          +toPercent(usr.score, usr.maxScore) < 50
                            ? 'text-error-05'
                            : +toPercent(usr.score, usr.maxScore) > 49 &&
                              +toPercent(usr.score, usr.maxScore) <
                                course?.minScore * 100
                            ? 'text-warning-04'
                            : 'text-success-05'
                        }`}
                      >
                        {toPercent(usr.score, usr.maxScore)
                          ? toPercent(usr.score, usr.maxScore)
                          : 0}
                        % ({round2(usr.score ? usr.score : 0)}/
                        {round2(usr.maxScore)})
                      </span>
                      // `${toPercent(usr.score, usr.maxScore)}% (${round2(
                      //   usr.score ? usr.score : 0
                      // )}/${round2(usr.maxScore)})`
                    )}
                  </div>
                ),
              },
              {
                id: 'tasksRemaining',
                sortable: true,
                title: '',
                align: 'right',
                className: 'col-start-4 col-end-10 row-start-4 justify-end',
                hidden: course?.courseType === 'instruction',
                render: (usr) => (
                  <div className="z-[2]">
                    {usr?.tasksRemaining > 0 ? (
                      <Button
                        size="sm"
                        onClick={async (ev) => {
                          ev.stopPropagation();
                          const result = await axios.get(
                            `${
                              config.teodoc
                            }/results/list2?submitted=true&courseId=${
                              usr.courseId
                            }&userId=${
                              usr.studentId || usr.userId
                            }&offset=0&count=1`
                          );
                          if (result?.data.length > 0) {
                            navigate(
                              `${correctionBasePath}/${usr.courseId}/${
                                usr.studentId || usr.userId
                              }/${result?.data[0]?.lessonId}`
                            );
                          }
                        }}
                      >
                        {t('pages.follow_up_courses.improve_button')}
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          const resultPath = `/${
                            i18n.language
                          }/follow-up/${organisationId}/result/${
                            usr.courseId
                          }/${usr.studentId || usr.userId}/lesson`;
                          navigate(resultPath);
                        }}
                        variant="outline"
                        className={`${
                          i18n.language === 'fr' &&
                          'w-max min-w-min max-w-[110px]'
                        }`}
                        /*
                        disabled={
                          usr.status == 'pending' && !usr.maxScore ? true : false
                        }
                        */
                      >
                        {t('pages.follow_up_courses.view_answers_button')}
                      </Button>
                    )}
                  </div>
                ),
              },
              {
                addTitleOnMobile: false,
                className: 'col-start-1 col-end-8 row-start-4',
                id: 'comment',
                align: 'right',
                title: '',
                hidden: course?.courseType === 'instruction',
                classNameDesktop: '!px-0',
                render: (usr: any) => (
                  <Button
                    variant="ghost"
                    className="relative z-[2] pl-0 md:px-0 lg:px-0 xl:px-2"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setUsr(usr);
                      setIsOpenCommentModal(true);
                    }}
                  >
                    {usr.nrOfStudentComments > 0 && (
                      <span className="z-1 absolute right-0 top-0 rounded border border-[#ff8a82] bg-[#ff3c2f] py-0 px-1 text-xs font-normal text-white">
                        {usr.nrOfStudentComments}
                      </span>
                    )}
                    {usr.nrOfStudentComments > 0 ? (
                      <CommentBoxFilled className="h-5 w-5 text-[#7d7d81]" />
                    ) : (
                      <CommentBox className="h-5 w-5 text-[#7d7d81]" />
                    )}
                  </Button>
                ),
              },
              {
                id: 'actionButton',
                sortable: false,
                title: '',
                className: 'col-start-9 row-start-1 justify-end',
                classNameDesktop: '!px-1',
                render: (usr: any) => (
                  <OptionsAction
                    menuItems={[
                      {
                        addIcon: true,
                        hidden: !(
                          Math.max(
                            authUser?.role || 0,
                            authUser?.activeOrganisationRole || 0
                          ) >= 90
                        ),
                        id: '2',
                        title: t('pages.follow_up_courses.manage_user'),
                        onClick: () => navigate(`${managePath}/${usr?.id}`),
                      },
                      {
                        id: '4',
                        title: t('pages.follow_up_courses.download_report'),
                        hidden: course?.courseType === 'instruction',
                        onClick: async (ev: any) => {
                          let downloadPath;
                          switch (course.courseType) {
                            case 'course':
                              downloadPath = 'course';
                              break;
                            case 'leervacature':
                              downloadPath = 'vacancy';
                              break;
                            case 'assessment':
                              downloadPath = 'assessment';
                              break;
                            default:
                              downloadPath = 'course';
                          }
                          const link = `${config.teoreport}/report?lang=${i18n.language}&jwt=${auth?.token}`;
                          publicAxios
                            .post(link, {
                              userId: usr.id,
                              courseId: course.id,
                              formType: 'courseOverview',
                              organisationId: organisationId,
                              noBirth:
                                (course?.courseType === 'course' ||
                                  course?.courseType === 'assessment') &&
                                true,
                              noDiploma:
                                (course?.courseType === 'course' ||
                                  course?.courseType === 'assessment') &&
                                true,
                              noLessons:
                                (course?.courseType === 'course' ||
                                  course?.courseType === 'assessment') &&
                                true,
                            })
                            .then((result: any) => {
                              setShowNotification({
                                message:
                                  t('pages.report.emailed') +
                                  ` (${result?.data?.email})`,
                                delay: 5000,
                              });
                            });
                        },
                      },
                      {
                        id: '4',
                        title: t(
                          'pages.follow_up_courses.download_certificate'
                        ),
                        hidden: course?.courseType === 'instruction',
                        onClick: async (ev: any) => {
                          const promises = [];
                          const link = `${config.teoreport}/report/?lang=${i18n.language}&jwt=${auth?.token}`;
                          promises.push(
                            publicAxios.post(link, {
                              download: true,
                              lang: i18n.language,
                              userId: usr.id,
                              formType: 'certificate',
                              courseId: usr.courseId,
                              organisationId: organisationId,
                            })
                          );

                          Promise.allSettled(promises).then((results: any) => {
                            results[0]?.value?.data?.link &&
                              downloadPDFLink(results[0]?.value?.data?.link);
                          });
                          setShowNotification({
                            message: t(
                              'pages.report_page.report_download_message'
                            ),
                            delay: 5000,
                          });
                        },
                      },
                      {
                        addOnMobile: true,
                        id: '6',
                        onClick: () => {
                          setUsrSelected(usr);
                          setIsOpenRedoModal(true);
                        },
                        title: t('pages.follow_up_courses.redo_modal.title'),
                        hidden: course?.courseType === 'instruction',
                      },
                      {
                        addOnMobile: true,
                        itemDelete: true,
                        id: '5',
                        onClick: async (ev: any) => {
                          await axios.delete(
                            `${config.backend}/follow/${course?.id}/${
                              usr?.studentId || usr?.userId
                            }/`
                          );
                          refetch();
                          setResetUserTable(newUid(20));
                        },
                        title:
                          course?.courseType == 'assessment'
                            ? t(
                                'pages.follow_up_courses.remove_from_assessment'
                              )
                            : course?.courseType == 'leervacature'
                            ? t('pages.follow_up_courses.remove_from_vacancy')
                            : course?.courseType == 'instruction'
                            ? t(
                                'pages.follow_up_courses.remove_from_instruction'
                              )
                            : t('pages.follow_up_courses.remove_from_course'),
                      },
                    ]}
                    styleDropdown={{
                      top: '-30px',
                      left: 'auto',
                      right: '115%',
                    }}
                    screensUpdateTable="desktop"
                    pageDetail={pageDetail}
                    rowInfo={usr}
                    positionDropdown={true}
                    mobileHeader={{
                      render: (usr: any) => (
                        <div className="flex items-center">
                          <PersonAvatar
                            inline
                            id={usr.id}
                            name={usr.username || ''}
                            imageSrc={usr.picture || undefined}
                          ></PersonAvatar>
                          <div>
                            <div className="text-sm font-medium">
                              {usr.username}
                            </div>
                            <div className="text-xs text-[#7d7d81]">
                              {usr.email}
                            </div>
                          </div>
                        </div>
                      ),
                    }}
                  />
                ),
              },
            ]}
          />
        </Fragment>
      )}

      {tab === 'teachers' && (
        <Fragment>
          <DataTable
            key={resetUserTable}
            // filter={filter}
            rowPath={(user) => `${userPath}/${user.id}`}
            defaultSort={[{ id: 'username', desc: false }]}
            fetch={useGet.bind(null, '/teachers', {
              courseId: courseId,
            })}
            empty={{
              template: 1,
              text: t('empty_tenplate.teacher_list'),
              btn_text: t('empty_tenplate.btn_add_teacher'),
              btnClick: () => setShareSettings(true),
            }}
            screensUpdateTable="desktop"
            columns={[
              {
                title: t('columns.username'),
                id: 'username',
                className: 'col-start-1 col-end-9',
                render: (usr: Studentwithteams) => (
                  <div className="flex items-center">
                    <PersonAvatar
                      inline
                      id={usr.id}
                      name={usr.username || ''}
                      imageSrc={usr.picture || undefined}
                    ></PersonAvatar>
                    <div>
                      <div className="text-sm font-medium">{usr.username}</div>
                      <div className="text-xs text-[#7d7d81]">{usr.email}</div>
                    </div>
                  </div>
                ),
              },
              {
                addTitleOnMobile: true,
                className: 'col-start-1 col-end-4 row-start-2',
                id: 'createdAt',
                title: t('columns.added'),
                align: 'right',
                render: (usr: Studentwithteams) =>
                  usr?.startDate
                    ? format(new Date(usr?.startDate || ''), 'dd/MM/yyyy')
                    : '',
              },
              {
                id: 'actionButton',
                sortable: false,
                title: '',
                className: 'col-start-9 row-start-1 justify-end',
                render: (usr: any) => (
                  <OptionsAction
                    menuItems={[
                      {
                        addOnMobile: true,
                        itemDelete: true,
                        id: '5',
                        onClick: async (ev) => {
                          await axios.delete(
                            `${config.backend}/teach/${course?.id}/${
                              usr?.studentId || usr?.userId
                            }/`
                          );
                          refetch();
                          setResetUserTable(newUid(20));
                        },
                        title:
                          course?.courseType == 'assessment'
                            ? t(
                                'pages.follow_up_courses.remove_from_assessment'
                              )
                            : course?.courseType == 'leervacature'
                            ? t('pages.follow_up_courses.remove_from_vacancy')
                            : course?.courseType == 'instruction'
                            ? t(
                                'pages.follow_up_courses.remove_from_instruction'
                              )
                            : t('pages.follow_up_courses.remove_from_course'),
                      },
                    ]}
                    screensUpdateTable="desktop"
                    pageDetail={undefined}
                    rowInfo={usr}
                    mobileHeader={{
                      render: (usr: any) => (
                        <div className="flex items-center">
                          <PersonAvatar
                            inline
                            id={usr.id}
                            name={usr.username || ''}
                            imageSrc={usr.picture || undefined}
                          ></PersonAvatar>
                          <div>
                            <div className="text-sm font-medium">
                              {usr.username}
                            </div>
                            <div className="text-xs text-[#7d7d81]">
                              {usr.email}
                            </div>
                          </div>
                        </div>
                      ),
                    }}
                  />
                ),
              },
            ]}
          />
        </Fragment>
      )}

      {isOpenQRModal && (
        <QRModal
          closeModal={() => setIsOpenQRModal(false)}
          setIsOpenQRModal={setIsOpenQRModal}
          course={course}
          authUser={authUser}
        />
      )}
      {isOpenRedoModal && (
        <RedoModal
          closeModal={() => setIsOpenRedoModal(false)}
          setIsOpenRedoModal={setIsOpenRedoModal}
          usrSelected={usrSelected}
          courseId={courseId}
          setResetUserTable={setResetUserTable}
          newUid={newUid}
          isCourse={true}
        />
      )}

      {isOpenCommentModal && (
        <OpenCommentModal
          closeModal={() => setIsOpenCommentModal(false)}
          setIsOpenVersionModal={setIsOpenCommentModal}
          usrCourseId={courseId}
          usr={usr}
          course={course}
          authUser={authUser}
        />
      )}
    </div>
  );
}

export default FollowUpTeamCourseDetails;
