import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, Sidebar, InformationFilled } from '@teo/components/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { IconButton, Dropdown, PersonAvatar, Button } from '@teo/components';
import { NotificationButton } from '@/components/Notifications/NotificationButton';
import { axios } from '@/axios';
import { config } from '@/config';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps } from '@/models';
import i18n from 'i18next';

const LessonHeader = ({
  student,
  returnPath,
  isPreview,
  viewMobile,
  iSsmallLaptop,
  courseId,
  hideSidebar,
  setHideSidebar,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organisationId } = useParams();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  return (
    <header
      className="sticky top-0 z-[11] flex h-[80px] min-h-16 items-center justify-start border-b border-solid border-grey-02 bg-white p-3"
      data-testid="header"
    >
      {(isMobile || viewMobile) && (
        <IconButton
          className="my-auto mx-2"
          Icon={Sidebar}
          variant="ghost"
          onClick={() => {
            setHideSidebar(!hideSidebar);
          }}
        />
      )}

      <IconButton
        className="my-auto mx-2"
        Icon={ArrowLeft}
        variant="outline"
        onClick={() => {
          navigate(returnPath);
        }}
      />
      {student && (
        <div
          className="relative mr-2 ml-3 h-11 w-11 rounded-full text-center sm:mr-4"
          style={{
            background: `radial-gradient(closest-side, white 85%, transparent 90% 100%),conic-gradient(#3257fc ${
              student.progress ? Math.round(student.progress * 100) : 0
            }%, #1e1e2014 0)`,
          }}
        >
          <p className="pt-3 text-sm text-grey-07">
            {student.progress ? Math.round(student.progress * 100) : 0}&#37;
          </p>
        </div>
      )}
      {authUser && !isPreview && (
        <div
          className={`${
            !iSsmallLaptop && !viewMobile && 'ml-auto '
          } flex flex-row gap-4`}
        >
          <NotificationButton />
          <Dropdown>
            <Dropdown.Trigger>
              <PersonAvatar
                id={authUser.id}
                name={authUser.username as string}
                imageSrc={authUser.picture || undefined}
              />
            </Dropdown.Trigger>
            <Dropdown.Content>
              <Dropdown.Section className="flex items-center gap-3">
                <PersonAvatar
                  id={authUser.id}
                  name={authUser.username as string}
                  imageSrc={authUser.picture || undefined}
                />
                {authUser?.username}
              </Dropdown.Section>
              <Dropdown.Actions>
                <Dropdown.Link to={'/' + i18n.language + '/profile/details'}>
                  {t('links.profile')}
                </Dropdown.Link>
                <Dropdown.ExternalLink
                  href={t('external_links.help') as string}
                >
                  {t('links.help')}
                </Dropdown.ExternalLink>
                <Dropdown.Button
                  onClick={() => {
                    localStorage.removeItem('token');
                    navigate('/');
                  }}
                >
                  {t('links.logout')}
                </Dropdown.Button>
              </Dropdown.Actions>
            </Dropdown.Content>
          </Dropdown>
        </div>
      )}
    </header>
  );
};

export default LessonHeader;
